<template>
  <div class="services-page">
    <!-- <van-nav-bar
      :title="$t('navBar.mHeader.service')"
      left-arrow
      @click-left="$router.go(-1)"
    /> -->
    <div class="banner">
      <van-image :src="require('@/assets/images/about/about_banner.webp')" width="100%" height="380" fit="cover"/>
      <van-button size="large" type="warning" round @click="gotoPath">Contact Us <span><van-icon name="arrow" /></span></van-button>
    </div>
    <div class="cont_center">
    <div class="sc-banner">
      <div class="word font_32">ONE-STOP</div>
      <div class="word font_32">GAMBLING</div>
      <van-image 
      class="mascot"
      width="109"
      height="198" 
      :src="require('@/assets/images/about/about_mascot.webp')"
      />
      <div class="word_over">GAMING</div>
      <div class="description">GAME PLUS, a one-stop API "integration" solution provider for gambling and entertainment systems</div>
    </div>
    <div class="api-swipe">
      <div class="s-title">G-Plus APIs</div>
      <div class="s-description description-font">EXTREMELY FAST AND FLEXIBLE ACCESS EXPERIENCE</div>
      <!-- <van-swipe 
      class="service-swipes" 
      width="100" 
      height="140" 
      :loop="false"
      :show-indicators="false"
      >
         <van-swipe-item class="swipe-item" v-for="i in 4" :key="i">
          <van-image  :src="require(`@/assets/images/game/sw1.${i}.png`)"/>
         </van-swipe-item>
      </van-swipe> -->
    </div>
    <div class="our-service">
      <div class="s-title">OUR SERVICE</div>
      <van-swipe 
      class="service-swipe" 
      :loop="false"
      width="350"
      height="390"
      >
        <!-- 游戏轮播 -->
        <van-swipe-item class="swipe-item">
          <div class="service-card">
            <h3 class="service-card-title font_32">14+</h3>
            <p class="card-subtitle">游戏</p>
            <div class="game-thumbnails">
              <!-- 游戏缩略图 -->
              <van-image width="50" height="50" v-for="(game, index) in 12" :src="getImage(`sw1.${game}.png`)" :key="index" alt="游戏缩略图" />
            </div>
            <p class="footer-text">敬请期待更多游戏</p>
          </div>
        </van-swipe-item>

        <!-- 客户遍布国家轮播 -->
        <van-swipe-item class="swipe-item">
          <div class="service-card">
            <h3 class="service-card-title font_32">103+</h3>
            <p class="card-subtitle">客户遍布 103 个国家</p>
            <div class="stats">
              <p><span class="dot" style="background-color: #f5222d;"></span> 亚洲 42%</p>
              <p><span class="dot" style="background-color: #faad14;"></span> 欧洲 30%</p>
              <p><span class="dot" style="background-color: #722ed1;"></span> 美国 12%</p>
              <p><span class="dot" style="background-color: #1890ff;"></span> 非洲 10%</p>
            </div>
          </div>
        </van-swipe-item>

        <!-- 支持货币轮播 -->
        <van-swipe-item class="swipe-item">
          <div class="service-card">
            <h3 class="service-card-title font_32">14+</h3>
            <p class="card-subtitle">支持货币</p>
            <div class="currency-icons">
              <img v-for="(curry, ind) in currencyList" :key="ind" :src="curry" alt="currency" />
            </div>
            <p class="footer-text">品牌标识</p>
          </div>
        </van-swipe-item>

        <!-- 账户管理支持轮播 -->
        <van-swipe-item class="swipe-item">
          <div class="service-card">
            <h3 class="service-card-title font_32">账户管理支持</h3>
            <ul class="support-list">
              <li><van-icon name="coupon-o" color="#f5222d" /> 促销材料供应</li>
              <li><van-icon name="gift-o" color="#faad14" /> 免费/奖金/欢迎旋转</li>
              <li><van-icon name="like-o" color="#722ed1" /> 个性化优惠</li>
              <li><van-icon name="diamond-o" color="#1890ff" /> 独家预发布</li>
            </ul>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="about_info">
      <div class="info_row top">
        <div class="step" :class="index==1?'reverse':''" v-for="(top,index) in setpInfo.top" :key="index">
          <div class="left">
          <div class="info_title font_14">
          {{top.title}}
        </div>
        <div class="info_default font_8">{{top.content}}</div>
        </div>
        <div class="right">
          <van-image :src="require(`@/assets/images/about/about_${top.img}`)" height="80" width="80"/>
        </div>
        </div>
      </div>
      <div class="row2">
        <div class="item info_row step" v-for="(middle,index) in setpInfo.middle" :key="index">
          <div class="row_top">
          <div class="info_title font_14">
          {{middle.title}}
        </div>
        <div class="info_default font_8">{{middle.content}}</div>
        </div>
        <div class="row_bottom">
          <van-image :src="require(`@/assets/images/about/about_${middle.img}`)" height="80" width="80"/>
        </div>
        </div>
      </div>
      
      <div class="info_row bottom">
        <div class="step" v-for="(bottom,index) in setpInfo.bottom" :key="index">
          <div class="bottom_left">
          <div class="info_title font_14">
          {{bottom.title}}
        </div>
        <div class="info_default font_8">{{bottom.content}}</div>
        </div>
        <div class="bottom_right">
          <van-image :src="require(`@/assets/images/about/about_${bottom.img}`)" height="80" width="80"/>
        </div>
        </div>
      </div>
    </div>
    <div class="foot_info">
      <van-image :src="require('@/assets/logo.png')" width="140" height="24"/>
      <span class="compy_info info_default font_10">©2022 Gaming Plus.All rights reserved. Privacy PolicyCookies</span>
    </div>  
    </div>
    
    <div class="service-section">
      
      <!-- <p class="section-description">
        {{$t('companyProfile.content')}}
      </p> -->
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicePage',
  data() {
    return {
      currencyList: [
        require('@/assets/images/game/gold-coin.svg'),
        require('@/assets/images/game/gold-coin.svg'),
        require('@/assets/images/game/gold-coin.svg'),
      ],
      setpInfo:{
        top:[
          {
            title:'efficient and time-saving',
            content:`Possessing ample resources and a professional team to help you go live and operate in the shortest possible time, enhancing the platform's profitability efficiency`,
            img:'img1.png'
          },
          {
            title:'integrated API',
            content:`An aggregated interface that quickly integrates all categories of products, greatly simplifying the integration and management process`,
            img:'img2.png'
          },
          {
            title:'various wallet options',
            content:`GAMEPLUS supports multiple wallet modes,meeting the needs of different partners`,
            img:'img3.png'
          }
        ],
        middle:[
        {
            title:'liquid funds',
            content:`COST-EFFECTIVE ONE RECHARGE, EASILY MANAGED`,
            img:'img4.png'
          },
          {
            title:'various access modes',
            content:`GAMEPLUS offers multiple game access modes`,
            img:'img5.png'
          }
        ],
        bottom:[
        {
            title:'safe and professional',
            content:`GAMEPLUS has a professional technical team and rich industry experience, ensuring the safe and stable operation of the platform, and providing a comprehensive security protection system to safeguard the interests of partners and users`,
            img:'img6.png'
          }
        ]
      }
    };
  },
  methods:{
    getImage(imgName) {
      return require(`@/assets/images/game/${imgName}`);
    },
    gotoPath(){
      this.$router.push('contact')
    }
  }
};
</script>

<style lang="scss" scoped>

  .services-page {
  background-color: $main-bg-color;
  text-align: center;
  color: $white;
  padding-bottom: 30px;
  }

  .service-section {
  margin-bottom: 40px;
  padding: 0 20px 0 20px;
  }

  .section-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: $light-gray;
  }

 ::v-deep.our-service{
.service-swipe {
  margin-top: 30px;
  border-radius: 10px;
   .van-swipe__indicators{
    bottom: 0!important;
  }
  .van-swipe__indicator {
    width: 27px;
    height: 7px;
    border-radius: 25px;
  }
  }

  .van-swipe-item {
  //height: 300px; /* 设置固定高度，可以根据需要调整 */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  }

  .service-card {
  //background-color: $card-bg-color; /* 使用全局卡片背景色 */
  border-radius: 16px;
  padding: 20px;
  color: $white;
  text-align: left;
  width: 288px;
  height: 80%;
  border: 1px solid rgba(255,255,255,0.15);
  @include box-shadow($black, 0.3); /* 使用 Mixin 添加阴影 */
  }

  .service-card-title {
  //font-size: 24px;
  margin: 0;
  //color: $primary-color; /* 使用全局主颜色 */
  }

  .card-subtitle {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: $light-gray; /* 统一的浅灰色 */
  }

  .game-thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
  gap: 11px;
  margin-bottom: 11px;
  }

  .game-thumbnails img {
 // width: 30%;
  margin-bottom: 10px;
  border-radius: 5px;
  @include box-shadow($black, 0.2, 0, 1px, 3px); /* 为缩略图添加轻微阴影 */
  }

  .stats p,
  .support-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 5px 0;
  color: $white;
  }

  .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  }

  .footer-text {
  margin-top: 10px;
  font-size: 14px;
  color: $light-gray;
  }

  .currency-icons img {
  width: 15%;
  margin: 5px;
  }

  .support-list {
  list-style: none;
  padding: 0;
  }

  .support-list li {
  margin-bottom: 8px;
  }
  .van-swipe-item {
      display: flex;
      align-items: center;
      justify-content: center;
  }
 }
 //****************new**************/
 .cont_center{
  width: 90%;
  //margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 .banner{
  width: 100%;
  position: relative;
  height: 60%;

  .van-button{
    position: absolute;
    bottom: 20px;
    left:calc( 50% - 162.5px);
    width: 205px;
    height: 64px;
    font-size: 20px!important;
    background-color: $more-font-color;
    .van-icon{
      font-weight: 900;
      border: 3px solid $white;
      padding: 1px;
      border-radius: 50%;
    }
  }
 }
 .sc-banner{
  position: relative;
  padding-top: 8px;
  .word,.word_over{
    font-weight: 900;
    font-size: 32px;
  }
  .word{
    z-index: 0;
  }
  .word_over{
     position:absolute;
     top: 68px;
     left: 18%;
     z-index: 3;
     color: $more-font-color;
  }
  .description{
    width: 214px;
    margin: 100px auto 0;
    font-size: 10px;
    line-height: 12px;
   text-align: center;
    color: rgba(255,255,255,0.6);
  }
  .mascot{
    position:absolute;
    top: -19%;
    left: 25%;
    z-index: 1;
  }
 }
 .api-swipe{
  margin-top:40px;
  .s-description{
    margin: 20px 0;
  }
  .van-swipe-item{
    margin: 0 5px;
  }
 }
 .our-service{
  width: 100%;
  margin: 0 auto;
  .s-title{
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .service-swipe{
    margin-top: 0;
    //width: 100%;
  }
 }
.about_info{
  margin: 40px 20px;
  width: 40%;
  .info_title{
    margin-bottom: 12px;
  }
  .top{
    width: 335px;
    height: 334px;
    .step{
      height: 110px;
      padding: 0 20px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid;
      border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)) 1 1;
    }
    .step.reverse{
      flex-direction: row-reverse;
    }
    .left{
      text-align: left;
      
    }
  }
  .row2{
    display: flex;
    justify-content: space-around;
    align-items: center;
   .item.step{
     width: 160px;
     height: 180px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     .info_default{
      margin-bottom: 15px;
    }
   }
  }
  .bottom{
    width: 335px;
    height: 124px;
    display: flex;
    align-items: center;
    .step{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      .bottom_left{
        text-align: left;
      }
    }
  }
  .info_row{
    width: 100%;
    background: #232324;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    // border-image: linear-gradient(134deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 1 1;
  }
  .info_row{
    margin-bottom: 20px;
    
  }

}
.foot_info{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.s-title{
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

</style>

