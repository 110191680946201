const state = {
    notifications: [
      { id: 1, title: '通知1', date: '2023-01-01', content: '通知1的详细内容...', read: false },
      { id: 2, title: '通知2', date: '2023-01-02', content: '通知2的详细内容...', read: true },
      { id: 3, title: '通知3', date: '2023-01-03', content: '通知3的详细内容...', read: false }
    ]
  }
  
  const mutations = {
    markAsRead(state, notificationId) {
      const notification = state.notifications.find(n => n.id === notificationId)
      if (notification) {
        notification.read = true
      }
    }
  }
  
  const actions = {
    fetchNotifications() {
      // 模拟从 API 获取通知
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })
    }
  }
  
  const getters = {
    unreadCount(state) {
      return state.notifications.filter(n => !n.read).length
    }
  }
  
  export default {
    namespaced: true, // 使用命名空间，避免冲突
    state,
    mutations,
    actions,
    getters
  }
  