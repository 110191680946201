<!-- footInfo -->
<template>
    <div class='foot-info-page foot_info'>
        <van-image :src="require('@/assets/logo.png')" width="140" height="24"/>
        <span class="compy_info info_default font_10">{{$t('publice.footer')}}</span>
    </div>
</template>

<script>

export default {
components: {},
name:'FootInfo',
data() {
return {

};
},
computed: {},
watch: {},
methods: {

},
created() {

},
mounted() {

},
}
</script>
<style lang='scss' scoped>
.foot_info{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 80px;
}
</style>