<template>
  <!-- 开发厂商选项卡 -->
  <div class="developer-tbs developer-games">
    <van-tabs v-model="localActiveTab" type="card" swipeable @change="switchDeveloperByTabIndex">
    <van-tab v-for="(dev, index) in developers" :title="dev.name" :key="index">
      <template #title>
        <van-image :src="dev.pic" width="105" height="35" fit="contain" />
        <p class="game-count mb-2">{{ dev.count }} {{ $t('mobile.home.games') }}</p>
      </template>
    </van-tab>
  </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'DeveloperTabs',
  props: {
    developers: {
      type: Array,
      required: true
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      localActiveTab: this.activeTab || 0 // 将父组件传入的 activeTab 设置为本地状态
    };
  },
  watch: {
    // 当父组件修改 activeTab 时，同步更新 localActiveTab
    activeTab(newVal) {
      this.localActiveTab = newVal;
    }
  },
  methods: {
    switchDeveloperByTabIndex(index) {
      this.$emit('update:activeTab', index); // 触发父组件的 update:activeTab 事件
      this.$emit('developer-change', this.developers[index]); // 触发开发商切换事件
    }
  }
};
</script>

<style lang="scss" scoped>
.developer-games{
  padding: 15px  0!important;
}
.game-count {
  font-size: 12px;
  color: $primary-color;
  text-align: center;
}
</style>
