<template>
    <lazy-component>
      <van-list
        v-model="internalLoading" 
        :finished="finished"
        :finished-text="$t('mobile.home.noMore')"
        @load="loadMore"
      >
        <div v-for="(game, index) in games" :key="index" class="game-card">
          <!-- 使用 v-lazy 指令实现图片懒加载 -->
          <van-image
            :src="game.pic"
            :alt="game.name"
            fill="cover"
            class="game-image w-full blur-image transition-opacity h-full object-cover"
            v-lazy="game.pic"
            :loading-icon="defaultImage"
            lazy-load
            style="width: 110px;"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
  
          <div class="game-info">
            <div class="game-header">
              <span class="game-title" :class="game.pic_text || game.name.length > 14 ? 'text-xs leading-tight' : 'text-base  leading-tight'">{{ game.name }}</span>
            </div>
            <div class="game-details">
              <span class="game-rtp">RTP {{ game.prize_rate }}%</span>
              <span class="game-rate">{{$t('mobile.home.rates')}} {{ game.fee_rate }}%</span>
            </div>
            <div class="game-footer">
              <van-button
                round
                class="play-button gradient-gold text-[clamp(0.8rem, 5vw, 2rem)] max-w-full"
                
                @click="gameDetail(game)"
              >
                {{ game.product}}
              </van-button>
              <span class="game-recommendation" @click="toggleRecommendation(game)">
                <van-icon :name="game.status ? 'like' : 'like-o'" />
                <span class="plat">{{$t('mobile.home.platformRecommendations')}}</span>
              </span>
            </div>
          </div>
        </div>
      </van-list>
    </lazy-component>
  </template>
  
  <script>
  import { gameList } from '@/api/services/game'; // 确保接口路径正确
  
  export default {
    name: 'LazyGameList',
    props: {
      searchValue: {
        type: String,
        default: null
      },
      product: {
      type: String,
      default: null
    },
    gameType: {
      type: String,
      default: null
    },
      defaultImage: {
        type: String,
        default: require('@/assets/images/game/default.jpg')
      }
    },
    data() {
      return {
        games: [], // 初始化游戏列表
        internalLoading: false, // 本地 loading 状态
        finished: false, // 是否已加载完所有数据
        pageNumber: 1, // 分页页码
        pageSize: 15, // 每页数量
        total: 0, // 总数据量
        keywords: this.searchValue // 搜索关键词
      };
    },
    watch: {
      searchValue(val) {
        // 监听父组件传来的搜索关键词，并重置游戏列表和分页信息
        this.keywords = val;
        this.pageNumber = 1;
        this.finished = false;
        this.games = [];
        this.getGameList(); // 重新获取数据
      },
      product(){
        this.pageNumber = 1;
        this.finished = false;
        this.games = [];
        this.getGameList(); // 重新获取数据
      },
      gameType(){
        this.pageNumber = 1;
        this.finished = false;
        this.games = [];
        this.getGameList(); // 重新获取数据
      }
    },
    mounted() {
      this.getGameList(); // 初始化加载游戏列表
    },
    methods: {
      async getGameList() {
        console.log();
        
        if (this.finished) return; // 如果已经加载完成，直接返回
  
        this.internalLoading = true;
  
        try {
          // 请求游戏列表数据
          const response = await gameList({
            game_type:this.gameType,
            product: this.product,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            keywords: this.keywords // 将搜索关键词作为参数传递
          });
  
          const { rows, total } = response.data;
  
          // 如果是第一页，则覆盖游戏数据，否则追加
          if (this.pageNumber === 1) {
            this.games = rows;
          } else {
            this.games = [...this.games, ...rows];
          }
  
          // 更新总数据量和页码
          this.total = total;
          this.pageNumber += 1;
  
          // 如果游戏数量达到总数，标记为已完成
          if (this.games.length >= this.total) {
            this.finished = true;
          }
  
          // 将数据传递给父组件（如果需要）
          this.$emit('game-list-updated', this.games);
        } catch (error) {
          console.error('Game list load failed:', error);
        } finally {
          this.internalLoading = false;
        }
      },
      loadMore() {
        console.log('loadMore');
        
        this.getGameList(); // 加载更多游戏
      },
      gameDetail(game) {
        this.$emit('view-game-detail', game); // 通知父组件查看详情
      },
      toggleRecommendation(game) {
        this.$emit('toggle-recommendation', game); // 通知父组件切换推荐状态
      }
    }
  };
  </script>
<style lang="scss" scoped>
/* 重复使用样式 */
.game-card {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  padding: 15px;
  background-color: $dark-gray;
  border-radius: 10px;
}

::v-deep.game-image {
  width: 100%;
  border-radius: 16px;
  height: auto;
  aspect-ratio: 58/77; /* 保持宽高比 */
  object-fit: cover; /* 确保图片内容不失真，填充容器 */
  margin-right: 15px;
}

.game-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.game-header {
  display: flex;
  justify-content: space-between;
}

.game-title {
  color: $gold;
  //font-size: 18px;
  font-weight: bold;
}

.game-details {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  color: $star-filled-color;
  font-size: 14px;
}

.play-button {
  height: 40px;
  width: 58%;
  //font-size: 16px !important;
  padding: 5px 12px;
  color: $white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-recommendation {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $star-filled-color;
  font-size: 14px;
  .van-icon{
    margin-right: 3px;
  }
  .plat{
    font-size: 10px;
  }
}
.game-footer{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
</style>
