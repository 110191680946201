// src/api/axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 基础路径，可以在 .env 文件中配置
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情，比如添加 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // 处理请求错误
    if (error.response) {
      switch (error.response.status) {
        // case 401:
        //   // 未授权，跳转到登录页
        //   window.location.href = '/login';
        //   break;
        case 500:
          // 服务器错误
          console.error('Server Error:', error.response.data.message);
          break;
        default:
          console.error('Error:', error.response.data.message);
      }
    }
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  response => {
    // 对响应数据做一些事情
    return response.data;
  },
  error => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

export default axiosInstance;
