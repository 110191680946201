import { getToken, setToken, removeToken } from '@/utils/auth'
import { getUserInfo, login, logout } from '@/api/user'

const state = {
  token: getToken(),
  name: '',
  roles: []
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  }
}

const actions = {
  // 用户登录
  async login({ commit }, userInfo) {
    const { username, password } = userInfo
    const { data } = await login(username, password)
    commit('SET_TOKEN', data.token)
    setToken(data.token)
  },

  // 获取用户信息
  async getInfo({ commit }) {
    const { data } = await getUserInfo()
    const { name, roles } = data
    commit('SET_NAME', name)
    commit('SET_ROLES', roles)
    return data
  },

  // 用户注销
  async logout({ commit }) {
    await logout()
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    removeToken()
  },

  // 重置 token
  async resetToken({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    removeToken()
  }
}

export default {
  namespaced: true, // 启用命名空间，防止命名冲突
  state,
  mutations,
  actions
}
