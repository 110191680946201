<template>
    <van-popup v-model="proLoading" class="progress-popup">
      <van-progress
        v-if="proLoading"
        :percentage="progress"
        :pivot-text="$t('mobile.home.loading')"
        color="#FFA726"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </van-popup>
  </template>
  
  
  <script>
  import { ref } from 'vue';
import { gameOpen } from '@/api/services/game';
import i18n from '@/main'; // 引入i18n实例

// 封装全局状态
const proLoading = ref(false);
const progress = ref(0);
const errorMessage = ref('');

export default {
  name: 'ProgressBar',
  props: {
    gameId: {
      type: Number,
      default:null,
      required: false // 需要传递的游戏ID
    },
    target: {
      type: String,
      default: '_self' // 控制跳转的方式，默认在当前页面
    },
    pivotText: {
      type: String,
      default: 'loading' // 加载进度条文本
    }
  },
  setup() {
    return {
      proLoading,
      progress,
      errorMessage
    };
  },
  watch: {
    gameId: {
      immediate: true,
      handler(newGameId) {
        if (newGameId) {
          this.handlerGoToDefault(newGameId, this.target);
        }
      }
    }
  },
  methods: {
    async getGameLink(id) {
      try {
        const res = await gameOpen({ id });
        return res.data || res;
      } catch (error) {
        console.error('Error fetching game link:', error);
        throw new Error(i18n.t('mobile.home.loadingError')); // 抛出i18n的自定义错误信息
      }
    },
    async handlerGoToDefault(id, target = '_self') {
      let loadInterval;

      try {
        proLoading.value = true;
        progress.value = 0;
        errorMessage.value = ''; // 清空错误信息

        // 模拟进度条更新
        loadInterval = setInterval(() => {
          if (progress.value < 90) {
            progress.value += 10;
          }
        }, 100);

        // 获取游戏链接
        const link = await this.getGameLink(id);

        // 清除进度条定时器并设置进度到100%
        clearInterval(loadInterval);
        progress.value = 100;

        // 模拟完成后执行跳转
        setTimeout(() => {
          proLoading.value = false;

          // 动态创建 <a> 标签进行跳转
          const a = document.createElement('a');
          a.href = link;
          a.target = target;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          // 重置进度条
          progress.value = 0;
        }, 500);
      } catch (error) {
        clearInterval(loadInterval); // 错误时清除定时器
        proLoading.value = true; // 显示加载状态
        progress.value = 0; // 重置进度条
        errorMessage.value = error.message; // 显示错误信息

        // 3 秒后自动关闭弹窗
        setTimeout(() => {
          proLoading.value = false;
          errorMessage.value = '';
        }, 2500);
      }
    }
  }
};
  </script>
  
  <style lang="scss" scoped>
  .error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

::v-deep.progress-popup {
  width: 80%;
  background-color: transparent !important;
  overflow: hidden; /* 隐藏滚动条，避免不必要的滚动 */
  .van-progress {
    height: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    span.van-progress__pivot {
      font-size: 12px !important;
    }
  }
  .error-message {
    font-size: 12px;
  }
}
::v-deep.van-popup.van-popup--center{
    background-color: transparent !important;
}
/* 针对 PC 端的样式 */
@media screen and (min-width: 1024px) {
progress-popup {
    width: 45%;  /* 在PC端调整宽度 */
    height: auto;
   
    .van-progress {
      height: 12px;  /* 增加PC端的进度条高度 */
      margin-top: 20px;
      span.van-progress__pivot {
        font-size: 14px !important;  /* 调整PC端字体大小 */
      }
    }
  }
}

  </style>
  