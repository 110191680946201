import MHome from '@/views/mobile/home/Home.vue'
import Cooper from '@/views/mobile/cooperation/index.vue'
import MContact from '@/views/mobile/contact/Contact.vue'
import MGames from '@/views/mobile/games/Games.vue'
import MAbout from '@/views/mobile/about/About.vue'
import MGameDetail from '@/views/mobile/games/GameDetail.vue'
import Service from '@/views/mobile/service/index.vue'
import MRegistration from '@/views/mobile/user/Registration.vue'
import MLogin from '@/views/mobile/user/Login.vue'
import MPasswordLogin from '@/views/mobile/user/PasswordLogin.vue'
import MVerifyCode from '@/views/mobile/user/VerifyCode.vue'
import MNotifications from '@/components/mobile/Notifications.vue'
import NotificationDetail from '@/components/mobile/NotificationDetailTemp.vue'
import Search from '@/components/mobile/Search.vue'
import GameDefaultList from '@/components/mobile/GameDefaultList.vue'
import DeveloperGames from '@/components/mobile/DeveloperGamesTemp.vue'
import MDemo from '@/views/mobile/games/Demo.vue'

const mobileRoutes = [
  {
    path: '/',
    redirect: '/mobile/home'
  },
  {
    path: '/mobile/home',
    name: 'HomeS',
    component: MHome
  },
  {
    path: '/mobile/registration',
    name: 'MRegistration',
    component: MRegistration
  },
  {
    path: '/mobile/login',
    name: 'MLogin',
    component: MLogin
  },
  {
    path: '/mobile/passwordLogin',
    name: 'MPasswordLogin',
    component: MPasswordLogin
  },
  {
    path: '/mobile/verifyCode',
    name: 'MVerifyCode',
    component: MVerifyCode
  },
  {
    path: '/mobile/cooper',
    name: 'Cooper',
    component: Cooper,
    meta: { requiresAuth: true } // 需要登录才能访问
  },
  {
    path: '/mobile/contact',
    name: 'Contact',
    component: MContact,
    meta: { requiresAuth: true } // 需要登录才能访问
  },
  {
    path: '/mobile/notifications',
    name: 'Notifications',
    component: MNotifications,
    meta: { requiresAuth: true } // 需要登录才能访问
  },
  {
    path: '/mobile/notifications/:id',
    name: 'NotificationDetail',
    component: NotificationDetail,
    meta: { requiresAuth: true } // 需要登录才能访问
  },
  {
    path: '/mobile/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/mobile/games',
    name: 'GamesS',
    component: MGames
  },
  {
    path: '/mobile/demo',
    name: 'MDemo',
    component: MDemo
  },
  {
    path: '/mobile/developers',
    name: 'MDeveloperGames',
    component: DeveloperGames
  },
  {
    path: '/mobile/about',
    name: 'AboutS',
    component: MAbout
  },
  {
    path: '/mobile/games/:id',
    name: 'GameDetailS',
    component: MGameDetail
  },
  {
    path: '/mobile/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/mobile/gamedefaultlist',
    name: 'GameDefaultList',
    component: GameDefaultList
  }
]

export default mobileRoutes
