import Vue from 'vue';
import VueRouter from 'vue-router';

// -----------desktop routes
import desktopRoutes from './modules/desktopRoutes';

// ---------mobile routes
import mobileRoutes from './modules/mobileRoutes';

Vue.use(VueRouter);

const routes = [
  ...desktopRoutes,
  ...mobileRoutes,
  { path: '*', redirect: '/' } // 捕获所有未定义路由的 404 处理
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // 每次路由切换时，滚动到页面顶部
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isMobile = window.innerWidth <= 768;
  if (isMobile && !to.path.startsWith('/mobile') && to.path !== '/') {
    next({ path: '/mobile' + to.path });
  } else if (!isMobile && to.path.startsWith('/mobile')) {
    next({ path: to.path.replace('/mobile', '') });
  } else if (isMobile && to.path === '/') {
    next({ path: '/mobile/home' });
  } else {
    next();
  }
});

export default router;