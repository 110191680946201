<template> 
  <div class="m-home">

    <!-- 使用 keep-alive 缓存页面 -->
    <keep-alive>
      <div >
        <Banner />
        <GameTypeGrid :options="{ data: gameType }" />
        <PopularGames  />
        <DeveloperSelectionLazy />
        <GameListLazy title="各类型游戏" :options="{ data: gameType }" />
        <FootInfo />
      </div>
    </keep-alive>
  </div>
</template>

<script>
// 按需加载组件
const Banner = () => import('@/views/mobile/home/Banner.vue');
const GameTypeGrid = () => import('@/views/mobile/home/GameTypeGrid.vue');
const DeveloperSelectionLazy = () => import('@/views/mobile/home/DeveloperSelection.vue');
const PopularGames = () => import('@/views/mobile/home/PopularGames.vue');
const GameListLazy = () => import('@/views/mobile/home/GameList.vue');
const FootInfo = () => import('@/components/mobile/FootInfo.vue');

export default {
  name: 'HomeS',
  components: {
    Banner,
    GameTypeGrid,
    DeveloperSelectionLazy,
    PopularGames,
    GameListLazy,
    FootInfo
  },
  computed: {
    gameType() {
      const storedGameType = this.$store.getters['common/gameType'];
      return storedGameType || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.m-home {
  padding-bottom: 20px;
  min-height: 100vh;
  will-change: transform, opacity; /* 启用硬件加速，防止渲染问题 */
  transform: translateZ(0); /* 启用GPU加速，解决黑屏问题 */
  overflow-x: hidden; /* 禁止横向滚动 */
  -webkit-overflow-scrolling: touch; /* iOS系统的流畅滚动 */
}
</style>
