<template>
  <div class="contact-page" >
    <!-- Navigation Bar -->
    <!-- <van-nav-bar
      :title="$t('navBar.header.contactUs')"
      left-arrow
      @click-left="$router.go(-1)"
    /> -->

    <!-- Contact Content -->
    <!-- <div class="contact-content">
      <div class="buttons-group">
        // Telegram Button
        <div class="button-item">
          <button class="btn btn-telegram">
            <i class="iconfont icon-telegram1"></i>  Telegram
          </button>
        </div>

       //WhatsApp Button
        <div class="button-item">
          <button class="btn btn-whatsapp">
            <i class="iconfont icon-whatsapp"></i> WhatsApp
          </button>
        </div>
      </div>

     // Address 
      <div class="address">
        <span>某某路123号</span>
      </div>
    </div> -->

    
    <!-- 头部Banner -->
    <div class="banner">
      <van-image 
      width="100%"
      height="200px"
      fit="cover"
      :src="require('@/assets/images/contact/contact_banner.jpg')" />
    </div>
        <!-- 标题和描述 -->
    <div class="title-section">
      <h1>CONTACT</h1>
      <p>We Are Committed To Establishing Partnerships Worldwide</p>
    </div>

    <div class="content">
    <van-image class="absolute alarm" :src="require('@/assets/images/contact/contact_alarm.png')"></van-image>
    <van-image class="absolute money" :src="require('@/assets/images/contact/contact_money.png')"></van-image>
    <van-image class="absolute money1" :src="require('@/assets/images/contact/contact_money1.png')"></van-image>
    <!-- 联系表单 -->
    <van-form @submit="onSubmit" class="contact-form">
      <!-- 姓名 -->
      <van-field
        v-model="formData.name"
        title="ffff"
        label="Your name"
        placeholder="Enter your name"
        required
        :error-message="errors.name"
      />

      <!-- 邮箱 -->
      <van-field
        v-model="formData.email"
        label="Email"
        placeholder="Enter your email"
        type="email"
        required
        :error-message="errors.email"
      />

      <!-- 选择通信方式 -->
      <van-field
        v-model="formData.messenger"
        label="Preferred Messenger"
        placeholder="Please choose"
        required
        :error-message="errors.messenger"
        readonly
        @click="showMessengerPicker = true"
      />
      <van-popup v-model="showMessengerPicker" position="bottom" >
        <van-picker
          show-toolbar
          :columns="messengerOptions"
          @confirm="onMessengerConfirm"
          @cancel="showMessengerPicker = false"
        />
      </van-popup>

      <!-- 信息输入框 -->
      <van-field
        v-model="formData.message"
        label="Message"
        type="textarea"
        rows="4"
        placeholder="Please provide a brief summary."
        required
        :error-message="errors.message"
      />

      <!-- 提交按钮 -->
      <div class="submit-button">
        <van-button  round block native-type="submit">
          Submit
        </van-button>
      </div>
    </van-form>

    <!-- 联系信息 -->
    <div class="contact-info">
      <h2>Contact Us</h2>
      <p>
        If you want learn more about our services and solutions, complete the form and one of our experts will be in touch shortly.
      </p>
      <!-- 社交媒体 -->
      <div class="social-icons">
        <van-image
          fit="contain"
          :src="require('@/assets/images/contact/contact_link.png')"
        />
        <van-image
          fit="contain"
          :src="require('@/assets/images/contact/contact_instagram.png')"
        />
        <van-image
          fit="contain"
          :src="require('@/assets/images/contact/contact_facebook.png')"
        />
        <van-image
          fit="contain"
          :src="require('@/assets/images/contact/contact_twitter.png')"
        />
        <van-image
          fit="contain"
          :src="require('@/assets/images/contact/contact_phone.png')"
        />
      </div>

      <!-- 底部图片 -->
      <div class="footer-image">
        <van-image height="164" :src="require('@/assets/images/contact/contact_mascot.png')" />
      </div>
    </div>

    </div>
   


  </div>
</template>


<script>
export default {
  name: 'ContactS',
  computed: {
    commonConfig() {
      return this.$store.getters['common/commonConfig'];
    },
  },
    data() {
    return {
      formData: {
        name: '',
        email: '',
        messenger: '',
        message: '',
      },
      errors: {
        name: '',
        email: '',
        messenger: '',
        message: '',
      },
      showMessengerPicker: false,
      messengerOptions: ['WhatsApp', 'Telegram', 'WeChat', 'Messenger'],
    };
  },
  methods: {
    // 处理提交
    onSubmit() {
      if (!this.formData.name) {
        this.errors.name = 'Name is required';
      } else if (!this.formData.email) {
        this.errors.email = 'Email is required';
      } else if (!this.formData.messenger) {
        this.errors.messenger = 'Messenger is required';
      } else if (!this.formData.message) {
        this.errors.message = 'Message is required';
      } else {
        // 提交表单逻辑
        console.log('Form submitted', this.formData);
      }
    },
    // 选择通信方式
    onMessengerConfirm(value) {
      this.formData.messenger = value;
      this.showMessengerPicker = false;
    },
  },
  }
</script>


<style lang="scss" scoped>
.dark-theme {
  /* 在此引入深色主题的其他样式 */
  --van-picker-background-color: #2b2b2b;
  --van-picker-toolbar-background-color: #3a3a3a;
  --van-picker-title-text-color: #ffffff;
  --van-picker-confirm-action-color: #ffffff;
  --van-picker-cancel-action-color: #999999;
  --van-picker-column-text-color: #ffffff;
  --van-picker-item-text-color: #bfbfbf;
  --van-picker-item-active-text-color: #ffffff;
  --van-picker-toolbar-border-color: #444444;
  --van-picker-selected-item-background-color: #444444;
  --van-picker-selected-item-text-color: #ffffff;
}
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $main-bg-color; // 使用全局定义的背景颜色变量
  min-height: calc(100vh - 198px);
  padding: 20px;
}
.van-nav-bar{
  width: 100%;
}
.contact-content {
  margin-top: 10%;
  background-color: $dark-gray-bar; // 使用全局
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  @include box-shadow($black, 0.5, 0, 2px, 4px); // 使用全局定义的 Mixin 添加阴影效果
  text-align: center;
  color: $white;
}

.buttons-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.button-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 25px;
  background-color: #f5d8ae;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn:hover {
  background-color: #e6a23c;
}

.btn-telegram {
  background-color: #0088cc;
  color: #fff;
}

.btn-whatsapp {
  background-color: #25d366;
  color: #fff;
}

.icon-telegram1 {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.icon-whatsapp {
  margin-right: 10px;
  width: 20px;
  height: 24px;
  font-size: 24px;
}

.address {
  font-size: 16px;
  color: #f5d8ae;
  margin-top: 10px;
}
.contact-page {
  background-color: #0D0D0D;
  padding: 20px 20px 65px;
  color: #fff;
}

.banner {
  width: 100%;
  //height: 115px;
  border-radius: 16px;
  overflow: hidden;
}
.content{
  background: #121213;
border-radius: 16px 16px 16px 16px;
border: 1px solid rgba(255,255,255,0.15);
position: relative;
.van-image.absolute{
  position: absolute;
  object-fit: cover;
}
.alarm{
  top: -39px;
  right: -15px;
  height: 102px;
  width: 102px;
}
.money{
  top: 300px;
  right: -20px;
  height: 100px;
  width: 60px;
}
.money1{
  top: 67px;
  left: -18px;
  width: 45px;
  height: 95px;
}
}
.title-section {
  text-align: center;
  margin: 20px 0;
}

.title-section h1 {
  font-weight: 900;
  font-size: 32px;
   margin-top: 0;
}

.title-section p {
  font-size: 10px;
  color: $default-font-color;
  margin-top: 10px;
}

::v-deep.contact-form {
  width: 90%;
  margin-top: 0px;
  padding: 0 10px 10px;
  background-color: $main-bg-color;
  border-radius: 16px;
  .van-field__label {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: $white;
    margin-bottom: 12px;
  }
  .van-cell{
    flex-direction: column;
    background-color: transparent!important;
    &::after{
      border-width: 0;
    }
    .van-field__control{
      background-color: rgba(255,255,255,0.04)!important;
      border-radius: 8px;
      min-height: 34px;
      padding: 0 12px;
    }
  }
}


.submit-button {
  margin-top: 20px;
  .van-button{
    background-color: $more-font-color;
    border-width: 0;
    .van-button__text{
      font-size: 16px;
    }
  }
}

.contact-info {
  margin: 30px 0;
  text-align: center;
}

.contact-info h2 {
  font-weight: bold;
  font-size: 24px;
}

.contact-info p {
  width: 90%;
  font-size: 10px;
  color: $default-font-color;
  margin: 10px auto;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
  .van-image{
    height: 16px;
    width: 16px;
  }
}

.footer-image {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

</style>
