<template>
    <div class="code-input" @paste="handlePaste">
      <van-field
        v-for="(digit, index) in length"
        :key="index"
        v-model="code[index]"
        maxlength="1"
        type="digit"
        class="code-box"
        :class="{ 'highlight-border': code[index] !== '' }"
        :ref="'codeField' + index"
        @input="handleInput(index, $event)"
        @keydown.backspace="handleDelete(index)"
        @focus="handleFocus(index)"
        @blur="handleBlur(index)"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'MVerificationInput',
    props: {
      value: {
        type: Array,
        required: true
      },
      length: {
        type: Number,
        default: 6
      }
    },
    data() {
      return {
        code: [...this.value] // 深拷贝父组件传入的值
      };
    },
    watch: {
    // 监听父组件传入的 `value`，只有当新值和旧值不相同时，才更新内部 `code`
    value(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.code)) {
        this.code = [...newVal];
      }
    },
    // 同步内部 `code` 到父组件，只有当新值和旧值不相同时，才触发更新
    code(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.value)) {
        this.$emit('input', newVal);
      }
    }
  },
    methods: {
      handleInput(index, event) {
        const value = event;
        if (value && /^\d$/.test(value)) {
          this.$set(this.code, index, value); // 更新指定位置的验证码
  
          // 自动跳到下一个输入框
          setTimeout(() => {
            if (index < this.length - 1) {
              const nextField = this.$refs['codeField' + (index + 1)][0];
              if (nextField) {
                nextField.focus();
              }
            }
          }, 0);
        }
      },
      handleDelete(index) {
        if (this.code[index] === '' && index > 0) {
          const prevField = this.$refs['codeField' + (index - 1)][0];
          if (prevField) {
            prevField.focus();
          }
        }
      },
      handleFocus(index) {
        const currentField = this.$refs['codeField' + index][0];
        if (currentField) {
            currentField.style ={borderColor:'#ffcc00'}
        }
      },
      handleBlur(index) {
        const currentField = this.$refs['codeField' + index][0];
        if (currentField && this.code[index] === '') {
            currentField.style = {borderColor:'#ccc'}; 
        }
      },
      handlePaste(event) {
        const pastedData = (event.clipboardData || window.clipboardData).getData('text');
        if (pastedData && /^\d+$/.test(pastedData)) {
          const pasteArray = pastedData.slice(0, this.length).split('');
  
          pasteArray.forEach((char, index) => {
            this.$set(this.code, index, char);
          });
  
          setTimeout(() => {
            if (pasteArray.length < this.length) {
              const nextField = this.$refs['codeField' + pasteArray.length][0];
              if (nextField) {
                nextField.focus();
              }
            } else {
              const lastField = this.$refs['codeField' + (this.length - 1)][0];
              if (lastField) {
                lastField.focus();
              }
            }
          }, 0);
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  ::v-deep.code-input {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 20px;
    .code-box {
        width: 40px;
        height: 50px;
        padding: 10px 5px;
        text-align: center;
        font-size: 20px;
        color: $white;
        background-color: $input-bg-color;
        border: 1px solid $border-color;
        border-radius: 5px;
        transition: border-color 0.3s ease;
        .van-field__control {
            color: $primary-color;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
          }
    }
    .highlight-border {
        border-color: $primary-color !important;
    }
  }
  
  </style>

  