<template>
  <div class="m-card">
    <van-nav-bar
      title="游戏"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <!-- 游戏页面内容 -->
    <p>这里是游戏页面。</p>
  </div>
</template>

<script>
export default {
  name: 'GamesS'
};
</script>
<style lang="scss" scoped>
  
</style>
