<template>
  <div class="developer-games  pt-10 px-4 pb-4">
    <van-nav-bar :title="$t('mobile.home.gameDevelopment')" left-arrow @click-left="$router.go(-1)">
      <template #right>
        <van-icon name="search" @click="showPopup = true" size="24" />
      </template>
    </van-nav-bar>

    <!-- 开发者列表弹窗 -->
    <van-popup class="more" v-model="showPopup" position="bottom" :style="{ height: '70%' }">
      <div class="popup-content">
        <van-row>
          <van-col span="24" class="popup-header">
            <span class="popup-title">{{ $t('mobile.home.developerList') }}</span>
            <van-icon name="close" @click="showPopup = false" />
          </van-col>
        </van-row>
        <van-row class="popup-developer-list" gutter="15">
          <van-col
            v-for="(dev, index) in developers"
            :key="index"
            span="8"
            :class="{ 'is-active': activeTab === index }"
            @click="selectDeveloper(dev, index)"
          >
            <van-image :src="dev.pic" width="100" height="30" fit="contain" />
            <p class="game-count pb-1">{{ dev.count }} {{ $t('mobile.home.games') }}</p>
          </van-col>
        </van-row>
      </div>
    </van-popup>

    <!-- 使用 DeveloperTabs 组件 -->
    <DeveloperTabs
      :developers="developers"
      :activeTab.sync="activeTab"
      @developer-change="switchDeveloper"
    />
    <div class="game-list">
      <!-- 使用封装后的 LazyGameList 组件 -->
    <LazyGameList
      :product="product"
      @game-list-updated="handleGameListUpdated"
      @view-game-detail="viewGameDetail"
      @toggle-recommendation="toggleRecommendation"
    />
    </div>
    <!-- 加载进度条 -->
    <ProgressBar :gameId="selectedGameId" />
  </div>
</template>

<script>
import DeveloperTabs from '@/components/mobile/DeveloperTabs.vue';
import ProgressBar from '@/components/publice/ProgressBar.vue';
import LazyGameList from '@/components/publice/LazyGameList.vue';

export default {
  name: 'DeveloperGamesTemp',
  components: {
    DeveloperTabs,
    ProgressBar,
    LazyGameList,
  },
  data() {
    return {
      selectedGameId: null,
      product: '', // 当前选中的产品
      activeTab: parseInt(this.$route.query.activeTab) || 0, // 当前激活的tab
      showPopup: false, // 控制弹窗的显示状态
    };
  },
  computed: {
    developers() {
      // 从 sessionStorage 或 store 中获取开发者数据
      return this.$store.getters['common/gameProduct'];
    },
  },
  created() {
    // 初始化产品，并加载游戏列表
    this.product = this.$route.query.shortname || this.developers[0]?.shortname || '';
  },
  methods: {
    selectDeveloper(developer, index) {
      this.switchDeveloper(developer, index);
    },
    switchDeveloper(developer, index) {
      this.activeTab = index;
      this.product = developer.shortname;
      this.showPopup = false;
    },
    handleGameListUpdated() {
     // console.log('游戏列表更新:', games);
    },
    viewGameDetail(game) {
      this.selectedGameId = game.id;
      //console.log('查看游戏详情:', game);
    },
    toggleRecommendation(game) {
      game.status = !game.status;
      //console.log('切换推荐状态:', game.name);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.developer-games {
  //background-color: $dark-bg-color;
  //padding: 15px;
  min-height: calc(100% - 30px);
  //overflow-y: auto;
  .van-nav-bar{
    background-color: transparent!important;
  }
  .developer-header {
    margin-bottom: 15px;

    .van-tabs {
      background-color: transparent;
      overflow-x: auto;
      white-space: nowrap;
    }

    .van-tab {
      display: inline-block;
      padding: 0 15px;
      text-align: center;
      border-width: 0;

      .game-count {
        color: $primary-color;
        margin-top: 0;
      }
    } 
  }

  .van-popup.more {
    padding: 15px 5px;
    background-color: rgba($dark-gray, 0.85) !important;

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
    }
  }

  .popup-developer-list {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 10px;

    .van-col {
      text-align: center;
      margin-bottom: 12px;

      .game-count {
        font-size: 14px;
        color: $light-gray;
        margin: 0 0 5px;
      }
    }

    .van-col.is-active {
      border: 1px solid $primary-color;
      border-radius: 5px;
    }
  }
}
.game-list{
  max-height: calc(100vh - 100px);
}
.loading-text, .finished-text {
  text-align: center;
  font-size: 14px;
  margin: 20px 0;
  color:$secondary-font-color;
}
</style>       
