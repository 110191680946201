<template>
    <div class="demo-page m-card">
      <van-nav-bar
        title="Yummy Carnival"
        left-text="Quit"
        right-text="More"
        @click-left="quitGame"
        @click-right="moreOptions"
      />
  
      <div id="GameDiv" class="game-container">
        <div id="Cocos3dGameContainer" class="game-box">
          <canvas id="GameCanvas" tabindex="99"></canvas>
          <input
            class="cocosEditBox"
            id="EditBoxId_1"
            type="text"
            style="display: none;"
          />
        </div>
      </div>
  
      <van-button type="primary" block @click="startGame">
        Start Game
      </van-button>
    </div>
  </template>
  
  <script>
  export default {
    name: "MobileGameDemo",
    methods: {
      quitGame() {
        // 实现退出游戏的逻辑
        console.log("Quit the game");
      },
      moreOptions() {
        // 实现更多选项逻辑
        console.log("More options");
      },
      startGame() {
        // 开始游戏逻辑
        console.log("Start the game");
      },
    },
    mounted() {
      // 模拟游戏加载逻辑
      const canvas = document.getElementById("GameCanvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 360;
      canvas.height = 640;
      ctx.fillStyle = "#FF5733";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = "20px Arial";
      ctx.fillStyle = "#fff";
      ctx.fillText("Welcome to the Mobile Demo", 30, 320);
    },
  };
  </script>
  
  <style scoped>
  .game-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  
  #GameCanvas {
    width: 360px;
    height: 640px;
    border: 1px solid #ddd;
  }
  
  .footer {
    text-align: center;
    padding: 10px;
    color: #888;
    background-color: #2e2e2e;
  }
  
  .van-button {
    margin-top: 20px;
    background-color: #ffcc00;
    color: #fff;
  }
  </style>
  