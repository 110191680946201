const getters = {
    unreadNotifications: state => state.notifications.notifications.filter(n => !n.read),
    unreadCount: state => state.notifications.notifications.filter(n => !n.read).length,
    token: state => state.user.token,
    roles: state => state.user.roles,
    name: state => state.user.name,
    sidebar: state => state.app.sidebar,
    //permission_routes: state => state.permissions.routes
    commonConfig: state => state.common.commonConfig,
    gameHelp: state => state.common.gameHelp,
    gameType: state => state.common.gameType,
    gameBanner: state => state.common.gameBanner,
    gameProduct: state => state.common.gameProduct,
    gameRankingList: state => state.common.gameRankingList,
    gameTypesData: state => state.game.gameTypesData,
  }
  
  export default getters
  