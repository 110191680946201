import Home from '@/views/desktop/home/Home.vue'
import GameDetail from '@/views/desktop/games/GameDetail.vue'
import PService from '@/views/desktop/service/index.vue'
import PContact from '@/views/desktop/contact/index.vue'
import PCooperation from '@/views/desktop/cooperation/index.vue'
import DAbout from '@/views/desktop/About.vue'
import DeveloperGames from '@/components/desktop/DeveloperGamesTemp.vue'
import Copyright from '@/views/desktop/footer/Copyright.vue'
import Disclaimer from '@/views/desktop/footer/Disclaimer.vue'
import PrivacyPolicy from '@/views/desktop/footer/PrivacyPolicy.vue'
import PDemo from '@/views/desktop/games/Demo.vue'

const desktopRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'HomeD',
    component: Home
  },
  {
    path: '/games/:id',
    name: 'GameDetail',
    component: GameDetail
  },
  {
    path: '/developers',
    name: 'DeveloperGames',
    component: DeveloperGames
  },
  {
    path: '/service',
    name: 'PService',
    component: PService
  },
  {
    path: '/cooperation',
    name: 'PCooperation',
    component: PCooperation
  },
  {
    path: '/contact',
    name: 'PContact',
    component: PContact
  },
  {
    path: '/about',
    name: 'About',
    component: DAbout
  },
  {
    path: '/gameType',
    name: 'PDemo',
    component: PDemo
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: Copyright
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/privacyPolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
]

export default desktopRoutes
