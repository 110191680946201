<template>
    <div class="content-page">
      <h1>{{$t('publice.privacy')}}</h1>
      <SanitizedHtml :htmlContent="content" />
      <p>本隐私政策介绍了我们如何收集、使用、披露和保护您的个人信息。</p>
    </div>
  </template>
  <script>
  import SanitizedHtml from '@/components/publice/SanitizedHtml.vue';
  export default {
    name:'DPrivacyPolicy',
    components:{
      SanitizedHtml
    },
    computed: {
      title() {
        return this.$route.query.title || 'Default Title';
      },
      content() {
        return this.$route.query.content || 'Default Content';
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .content-page {
    padding: 20px;
    background-color: $dark-bg-color;
    color: $main-font-color;
    text-align: left;
    min-height: calc(100vh - 198px);
    h1 {
      color: $primary-color;
      margin-bottom: 20px;
    }
  }
  </style>
  