<template>
    <div class="content-page">
      <h1>{{$t('publice.copyright')}}</h1>
      <SanitizedHtml :htmlContent="content" />
      <p>本网站上所有内容均受版权保护，未经许可不得转载或复制。</p>
    </div>
  </template>
  <script>
  import SanitizedHtml from '@/components/publice/SanitizedHtml.vue';
  export default {
    name:'DCopyright',
    components:{
      SanitizedHtml
    },
    computed: {
    title() {
      return this.$route.query.title || 'Default Title';
    },
    content() {
      return this.$route.query.content || 'Default Content';
    }
  }
  }
  </script>
  <style lang="scss" scoped>
  .content-page {
    padding: 20px;
    background-color: $dark-bg-color;
    color: $main-font-color;
    text-align: left;
    min-height: calc(100vh - 198px);
    h1 {
      color: $primary-color;
      margin-bottom: 20px;
    }
  }
  </style>
  