import Vue from 'vue'
import Vuex from 'vuex'
import notifications from './modules/notifications'
import user from './modules/user'
import app from './modules/app'
//import permissions from './modules/permissions'
import common from './modules/common';
import game from './modules/game'
import getters from './getters'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notifications, // 通知模块
    user,          // 用户模块
    app,           // 应用程序模块
    //permissions    // 权限模块
    common,   //公共配置模块
    game,   //游戏模块
  },
  getters
})
