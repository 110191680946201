<template>
  <div class="game-type-page m-card">
    <h2 class="page-title">{{$t('publice.gameType')}}</h2>
    <div class="carousel-container">
      <el-carousel 
        :interval="5000" 
        arrow="always" 
        class="game-type-carousel" 
        indicator-position="none"
        :autoplay="false"
        height="170px">
        <el-carousel-item v-for="(chunk, index) in chunkedGameTypes" :key="index">
          <div class="game-type-cards">
            <div class="game-type-card" 
            v-for="(type, idx) in chunk" 
            :key="idx"
            :style="{ background:bgColorArr[idx]}"
            >
              <img :src="type.list.pic" :alt="type.list.name" class="game-type-image" />
              <div class="game-type-info">
                <!-- <span class="country-flag">{{ gameTypes[idx].flag }}</span> -->
                <span class="type-name">{{ type.list.name }}</span>
              </div>
              <div class="hover-info">
                <span class="game-description">
                  <i class="iconfont icon-hezuo"></i>
                  {{type.count}} {{$t('mobile.home.games')}}
                </span>
                <button @click="handlerPlay(type.list.name)" class="play-button">Play Now</button>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>



<script>
export default {
  name: 'GameTypePage',
  data() {
    return {
      bgColorArr:[
        'linear-gradient(90deg, #3A3831 0%, #4E4C31 100%)',
        'linear-gradient(270deg, #103E78 0%, rgba(227,128,48,0.05) 100%)',
        'linear-gradient(90deg, #3A3831 0%, #44522E 100%)',
        'linear-gradient(90deg, rgba(227,128,48,0.05) 0%, #4E3F2A 100%)',
        'linear-gradient(90deg, #3A3831 0%, #4E2A2A 100%)',
        'linear-gradient(90deg, #3A3831 0%, #1E6037 100%)',
        'linear-gradient(90deg, #3A3831 0%, #5B2A27 100%)',
        'linear-gradient(90deg, #3A3831 0%, #492D21 100%)',
        'linear-gradient(90deg, #3A3831 0%, #5B4727 100%)',
        'linear-gradient(90deg, #3A3831 0%, #3D2149 100%)'  
      ]
    };
  },
  computed: {
    gameType(){
      return this.$store.getters['common/gameType'];
    },
    chunkedGameTypes() {
      const chunkSize = 5; // 每次展示5个卡片
      return this.chunkArray(this.gameType, chunkSize);
    },
  },
  methods: {
    chunkArray(array, chunkSize) {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    },
    handlerPlay(type){
      this.$router.push({path:'/gameType',query:{type:type}})
    }
  },
};
</script>


<style lang="scss" scoped>
::v-deep.game-type-page {
  margin-top: 20px;
  color: $main-font-color;
  padding: 0 20px;
  border: 1px solid $light-border-color;
  border-radius: 8px;
  @include box-shadow($black, 0.8);

  .page-title {
    // font-size: 24px;
    // font-weight: bold;
     margin-bottom: 10px; // 减少标题下的留白
    // color: $primary-color;
  }

  .carousel-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 10px; // 减少上部留白
    margin-bottom: 10px; // 减少下部留白
  }

  .game-type-carousel {
    .el-carousel__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .game-type-cards {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    .game-type-card {
      width: 18%; // 确保一行展示4个卡片
      height: 140px; // 调整高度以减少留白
      background-color: $dark-gray-bar;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      box-shadow: 0 4px 10px $shadow-color;
      transition: transform 0.3s;
      margin: 0 10px;
      &:hover {
        transform: scale(1.05);

        .hover-info {
          opacity: 1;
          transform: translateY(0); /* 使hover-info内容上升至可见 */
        }
      }

      .game-type-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: brightness(0.7);
        transition: filter 0.3s ease-in-out;
      }

      .game-type-info {
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 5px 10px;
        border-radius: 5px;

        .country-flag {
          margin-right: 10px;
          font-size: 16px;
        }

        .type-name {
          font-size: 16px;
          font-weight: bold;
          color: $white;
        }
      }

      .hover-info {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-top: 5px;
        width: 100%;
        height: 35%; /* 使hover-info占卡片的1/3 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
        display: flex;
        flex-direction: column;
        justify-content: flex-end; /* 内容靠近底部 */
        align-items: flex-start; /* 内容靠左 */
        opacity: 0;
        transform: translateY(100%); /* 初始状态设置为卡片外不可见 */
        transition: all .7s cubic-bezier(.42,0,.03,1);
       

        .game-description {
          color: $white;
          font-size: 14px;
          margin-bottom: 5px;
          padding-left: 10px;
        }

        .play-button {
          width: 90%; /* 按钮横向铺满 */
          margin: 0 auto;
          //text-align: left; /* 文字靠左 */
          background: rgba($primary-color,0.8);
          border: none;
          padding: 4px 8px;
          color: $white;
          cursor: pointer;
          transition: background 0.5s ease;
          border-radius: 5px;
          &:hover {
            //color: $dark-active-font-color;
            background: $primary-color;
          }
        }
      }
    }
  }

  .el-carousel__arrow {
    color: $white;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    &:hover {
      background-color: $primary-color;
    }
  }
}
</style>


  