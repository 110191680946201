import { getCommonConfig, getGameHelp } from '@/api/common'; 
import { gameTypes, gameProducts,rankingList } from '@/api/services/game';
import { gameBanners } from '@/api/home/index';

const state = {
  commonConfig:  {}, // 确保是对象
  gameHelp: {},  // 确保是对象
  gameType: [], // 游戏类型
  gameBanner:[], // 确保是数组
  gameProduct: [], // 确保是数组
  gameRankingList: [], // 确保是数组
};


const mutations = {
  SET_COMMON_CONFIG(state, config) {
    state.commonConfig = config;
  },
  SET_GAME_HELP(state, help) {
    state.gameHelp = help;
  },
  SET_GAME_TYPE(state, types) {
    state.gameType = types;
  },
  SET_GAME_BANNER(state, banners) {
    state.gameBanner = banners;
  },
  SET_GAME_PRODUCT(state, products) {
    state.gameProduct = products;
  },
  SET_GAME_RANKING_LIST(state,rankingLists){
    state.gameRankingList = rankingLists;
  }
};

const actions = {
  /**
   * 通用数据获取方法
   * @param {Object} context - Vuex 上下文对象
   * @param {Object} payload - 包含 key, mutation, api 和 sessionKey
   */
  async fetchData({ commit, state }, { key, mutation, api, sessionKey }) {
    if (!state[key] || Object.keys(state[key]).length === 0|| (Array.isArray(state[key]) && state[key].length === 0)) {
      try {
        const { data } = await api();
        commit(mutation, data);
        if(sessionKey){
          sessionStorage.setItem(sessionKey, JSON.stringify(data));
        }
      } catch (error) {
        console.error(`Error fetching ${key}:`, error);
      }
    }
  },

  // 单独的 action 调用 fetchData 来获取公共配置、游戏帮助、游戏类型等
  fetchCommonConfig({ dispatch }) {
    return dispatch('fetchData', {
      key: 'commonConfig',
      mutation: 'SET_COMMON_CONFIG',
      api: getCommonConfig,
      sessionKey: 'commonConfig',
    });
  },

  fetchGameHelp({ dispatch }) {
    return dispatch('fetchData', {
      key: 'gameHelp',
      mutation: 'SET_GAME_HELP',
      api: getGameHelp,
      sessionKey: 'gameHelp',
    });
  },

  fetchGameType({ dispatch }) {
    return dispatch('fetchData', {
      key: 'gameType',
      mutation: 'SET_GAME_TYPE',
      api: gameTypes,
      sessionKey: 'gameType',
    });
  },

  fetchGameBanner({ dispatch }) {
    return dispatch('fetchData', {
      key: 'gameBanner',
      mutation: 'SET_GAME_BANNER',
      api: gameBanners,
      sessionKey: 'gameBanner',
    });
  },

  fetchGameProduct({ dispatch }) {
    return dispatch('fetchData', {
      key: 'gameProduct',
      mutation: 'SET_GAME_PRODUCT',
      api: gameProducts,
      sessionKey: 'gameProduct',
    });
  },
  fetchRankingList({dispatch}){
    return dispatch('fetchData', {
      key: 'gameRankingList',
      mutation: 'SET_GAME_RANKING_LIST',
      api: rankingList,
    });
  }
};

const getters = {
  commonConfig: (state) => state.commonConfig,
  gameHelp: (state) => state.gameHelp,
  gameType: (state) => state.gameType,
  gameBanner: (state) => state.gameBanner,
  gameProduct: (state) => state.gameProduct,
  gameRankingList: (state) => state.gameRankingList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
