<!-- banner and api -->
<template>
<div class=''>
    <!-- Banner Section -->
    <div class="banner">
          <van-image :src="getImage('about/about_banner.webp')" fit="contain" />
          <van-button size="large" type="warning" round @click="gotoPath">
           {{$t('navBar.header.contactUs')}}
            <van-icon name="arrow" />
          </van-button>
        </div>

        <!-- Main Title Section -->
        <div class="sc-banner">
          <div class="word font_32 word-1">{{$t('mobile.about.oneStop')}}</div>
          <!-- <div class="word font_32">{{$t('mobile.about.gambling')}}</div> -->
          
          <!-- 吉祥物图片 -->
          <van-image class="mascot blur-image transition-opacity" width="109" height="198" :src="getImage('about/about_mascot.webp')" />
          
          <div class="word_over">{{$t('mobile.about.gambling')}}</div>
          <div class="description">
            {{$t('mobile.about.gamePlusDescription')}}
          </div>
        </div>

        <!-- G-Plus APIs Section -->
        <div class="api-swipe mb-12 mx-5">
          <div class="s-title font_20 mt-5">{{$t('mobile.about.gPlusAPIs')}}</div>
          <div class="s-description">{{$t('mobile.about.fastAndFlexible')}}</div>
         <div class="g-api-description"><SanitizedHtml :htmlContent="$t('mobile.about.gPApiDescription')" /></div>
        </div>
</div>
</template>

<script>
import SanitizedHtml from '@/components/publice/SanitizedHtml.vue';
export default {
components: {SanitizedHtml},
data() {
return {

};
},
computed: {},
watch: {},
methods: {
 
 gotoPath () {
   this.$router.push('contact');
 },
 getImage (imgName) {
   return require(`@/assets/images/${imgName}`);
 }
},
mounted() {

},
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.banner {
  position: relative;
  height: 60%;
  .van-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 205px;
    height: 64px;
    font-size: 20px;
    background-color: $more-font-color;
    .van-icon {
      font-weight: 900;
      border: 3px solid $white;
      border-radius: 50%;
    }
  }
}

.sc-banner {
  position: relative;
  padding-top: 8px;
  text-align: center; /* 确保标题和按钮居中对齐 */
  .word, .word_over {
    font-weight: 900;
    font-size: 32px;
     margin-bottom: 10px; /* 为每个标题增加间距 */
  }
  .word-1{
    color: $more-font-color;
    padding-right: 19%;
  }
  .word_over {
    position: absolute;
    top: 50px;
    left: 42%;
    transform: translateX(-50%); /* 确保文字居中 */
    z-index: 2;
   // color: rgba(255, 255, 255, 1); /* 半透明以更好的展示背景 */
    color: $more-font-color;
  }
  .description {
    width:90%;
    margin: 108px auto 0;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    line-height: 1.8;
    text-align: center;
    padding-bottom: 20px;
  }
  .mascot {
    position: absolute;
    top: -20%;
    left: 66%;
    z-index: 1;
    width: 20%;
  }
}
.api-swipe {
  //margin: 40px 20px 60px;
   //background: linear-gradient(180deg, #1e1e1e 0%, #0d0d0d 100%);
    background-color: $sidebar-bg-color;
    padding: 20px;
    border-radius: 12px;
    text-align: left; /* 左对齐，更适合阅读较长的文本 */
 .s-title {
    font-size: 22px;
    font-weight: bold;
    color: $white;
    margin-bottom: 20px;
     border-bottom: 1px solid;
    -o-border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)) 1 1;
    border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)) 1 1;
    padding-bottom: 10px;
     text-align: left; /* 左对齐，更适合阅读较长的文本 */
  }
   .s-description {
    text-align: center;
    font-size: 16px;  /* 调整字体大小，增加可读性 */
    line-height: 1.8; /* 增加行高，避免文字太紧凑 */
    color: rgba(255, 255, 255, 0.95); /* 稍微降低字体透明度 */
    margin-bottom: 15px; /* 段落之间的间距 */
  }
  .g-api-description {
    font-size: 14px;
    line-height: 1.8; /* 增加行高 */
    text-align: left;
    color: rgba(255, 255, 255, 0.7); /* 稍微降低透明度，便于与标题区分 */
    text-indent: 2em; /* 首行缩进 */
    margin-bottom: 15px; /* 段落之间的间距 */
    white-space: normal; /* 确保文本自动换行 */
  }
   p {
    margin-bottom: 20px; /* 确保段落之间有合适的空白 */
  }
  .swipe-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* 为每个轮播图片设置右边距 */
  border-radius: 10px; /* 让图片有些圆角，更符合设计 */
}
.s-title {
  font-weight: bold;
  color: $white;
  text-align: center;
}

.swipe-item img {
  width: 100%;
  height: auto; /* 保持图片的宽高比 */
  max-height: 250px; /* 限制最大高度 */
  object-fit: cover; /* 确保图片不会拉伸 */
}
.api-swipe .api-feature {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.api-swipe .api-feature-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.api-swipe .api-feature-title {
    font-size: 18px;
    font-weight: bold;
}

.api-swipe .api-feature-description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
}

}
</style>