<template>
     <el-skeleton  :rows="6" animated 
    class="empty-temp pt-18 px-4" v-if="!isContentLoaded"> 
      <template #template>
        <!-- 模拟 Banner -->
        <div class="skeleton-banner rounded-2xl"></div>

        <!-- 模拟 G-Plus APIs -->
        <div class="skeleton-section ">
          <div class="skeleton-title rounded-lg"></div>
          <div class="skeleton-box rounded-2xl"></div>
        </div>

        <!-- 模拟 OUR SERVICE -->
        <div class="skeleton-section">
          <div class="skeleton-title rounded-lg"></div>
          <div class="skeleton-box rounded-2xl"></div>
        </div>

        <!-- 模拟 About Info -->
        <div class="skeleton-section">
          <div class="skeleton-title rounded-lg"></div>
          <div class="skeleton-box rounded-2xl"></div>
        </div>
      </template>
    </el-skeleton>

  </template>
  
  <script>
  export default {
    name: 'SkeletonLoader',
    props:{
      isContentLoaded:{
        type:Boolean
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  /* 骨架屏样式 */
.skeleton-banner {
  width: 100%;
  height: 200px;
  background:$empty-bg-color;
  margin-bottom: 20px;
}

.skeleton-section {
  margin-bottom: 30px;

  .skeleton-title {
    height: 20px;
    width: 150px;
    background:$empty-bg-color;
    margin: 0 auto 10px;
  }

  .skeleton-box {
    width: 100%;
    height: 180px;
    background:$empty-bg-color;
  }
}
.services-page {
  background-color: #0d0d0d;
  text-align: center;
  color: $white;
  padding-top: 0;
  padding-bottom: 20px;
  min-height: 100vh; /* 防止页面高度抖动 */
  will-change: transform, opacity;
  transform: translateZ(0); /* 硬件加速 */
}
  </style>
  