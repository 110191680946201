// untils/lazy-load.js
export default {
    inserted(el) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src; // 设置真正的图片 URL
            observer.unobserve(img); // 取消观察
          }
        });
      });
  
      // 观察图片
      observer.observe(el);
    }
  };
  