<template>
    <div class="about_info">
          <!-- Top Section -->
          <div class="info_row top">
            <div class="step" :class="index === 1 ? 'reverse' : ''" v-for="(top, index) in setpInfo.top" :key="index">
              <div class="left">
                <div class="info_title text-lg">{{ top.title }}</div>
                <div class="info_default text-base leading-6 text-white">{{ top.content }}</div>
              </div>
              <div class="right">
                <van-image :src="getImage(`about/about_${top.img}`)" height="80" width="80" />
              </div>
            </div>
          </div>

          <!-- Middle Section -->
          <div class="info_row middle grid grid-cols-2 gap-2">
            <div class="step" v-for="(middle, index) in setpInfo.middle" :key="index">
              <div class="left">
                <div class="info_title text-lg">{{ middle.title }}</div>
                <div class="info_default text-base leading-6">{{ middle.content }}</div>
              </div>
              <div class="right">
                <van-image :src="getImage(`about/about_${middle.img}`)" height="80" width="80" />
              </div>
            </div>
          </div>

          <!-- Bottom Section -->
          <div class="info_row bottom">
            <div class="step" v-for="(bottom, index) in setpInfo.bottom" :key="index">
              <div class="left">
                <div class="info_title text-lg">{{ bottom.title }}</div>
                <div class="info_default text-base leading-6">{{ bottom.content }}</div>
              </div>
              <div class="right">
                <van-image class='blur-image transition-opacity' :src="getImage(`about/about_${bottom.img}`)" height="80" width="80" />
              </div>
            </div>
          </div>
        </div>
  </template>
  
  <script>
  export default {
    name: 'AboutInfo',
    props: {
      sections: Array
    },
    computed: {
    setpInfo() {
      return {
        top: [
          {
            title: this.$i18n.t('mobile.about.efficientTimeSaving'),
            content: this.$i18n.t('mobile.about.ampleResources'),
            img: 'img1.png'
          },
          {
            title: this.$i18n.t('mobile.about.integratedAPI'),
            content: this.$i18n.t('mobile.about.apiDescription'),
            img: 'img2.png'
          }
        ],
        middle: [
          {
            title: this.$i18n.t('mobile.about.liquidFunds'),
            content: this.$i18n.t('mobile.about.costEffective'),
            img: 'img4.png'
          },
          {
            title: this.$i18n.t('mobile.about.accessModes'),
            content: this.$i18n.t('mobile.about.accessModesDescription'),
            img: 'img5.png'
          }
        ],
        bottom: [
          {
            title: this.$i18n.t('mobile.about.safeProfessional'),
            content: this.$i18n.t('mobile.about.safeProfessionalDescription'),
            img: 'img6.png'
          }
        ]
      };
    }
  },
  methods:{
    getImage (imgName) {
      return require(`@/assets/images/${imgName}`);
    }
  }
  }
  </script>
  
  <style lang="scss" scoped>
 .about_info {
  margin: 40px 20px;
  .info_row {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .top,.bottom{
    background: #232324;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    min-height: 110px;
  }
  .top .step{
    border-radius: 0px 0px 0px 0px;
      border: 1px solid;
      border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)) 1 1;
  }
  .step.reverse {
    flex-direction: row-reverse;
  }

  .info_title {
    font-weight: bold;
    margin-bottom: 12px;
  }

  .info_default {
    line-height: 1.6;
    margin-bottom: 10px;
    text-align: left;
     font-size: 14px;
   color: rgba(255, 255, 255, 0.7);
  }
@media (min-width: 768px) {
    .info_default {
        margin-bottom: 16px; /* 大屏幕设备上的段落间距更大 */
    }
}
  .left,.right {
    text-align: left;
  }
}
.middle{
    display: flex;
    justify-content: space-between;
    border-width: 0;
    .step{
      width: 50%;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;
      background: #232324;
      border-radius: 16px;
      padding: 10px 15px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      &:first-child{
        margin-right: 10px;
      }
      .left,.info_defaul{
        text-align: center;
      }
    }
    .step .van-image {
    margin-right: 12px; /* 图标和文字之间的间距 */
    vertical-align: middle; /* 确保图标与文字垂直居中 */
}
  }
  
  </style>
  