<template>
  <div class="login-page m-card">
    <van-nav-bar left-arrow @click-left="$router.go(-1)" />
    <div class="title">欢迎注册账号</div>
    <van-tabs v-model="activeTab" class="custom-tabs">
      <van-tab title="邮箱">
        <van-field 
          v-model="email" 
          placeholder="请输入邮箱" 
          class="custom-field" 
          :error-message="emailError" 
        />
      </van-tab>
      <van-tab title="手机号">
        <van-field 
          v-model="phone" 
          placeholder="请输入手机号" 
          class="custom-field" 
          :error-message="phoneError"
        />
      </van-tab>
      <van-tab title="Telegram">
        <van-field 
          v-model="telegram" 
          placeholder="请输入Telegram用户名" 
          class="custom-field"
          :error-message="telegramError"
        />
      </van-tab>
    </van-tabs>

    <van-button type="primary" block @click="onSubmit" class="custom-button">
      下一步
    </van-button>

    <p class="login-link">
      已有账号？ <span @click="goToLogin">立刻登录</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "MRegistration",
  data() {
    return {
      activeTab: 0, // 选中的 tab
      email: '',
      phone: '',
      telegram: '',
      emailError: '',
      phoneError: '',
      telegramError: ''
    };
  },
  methods: {
    validateEmail(email) {
      // 正则表达式验证邮箱格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validatePhone(phone) {
      // 简单的手机格式验证（可以根据需求调整）
      const phoneRegex = /^1[3-9]\d{9}$/; // 假设是中国手机号格式
      return phoneRegex.test(phone);
    },
    validateTelegram(telegram) {
      // Telegram 用户名必须是字母、数字或下划线，长度限制在5-32
      const telegramRegex = /^[a-zA-Z0-9_]{5,32}$/;
      return telegramRegex.test(telegram);
    },
    async onSubmit() {
      // 验证输入框内容
      if (this.activeTab === 0) {
        
        // 验证邮箱
        if (!this.email) {
          this.emailError = '请输入邮箱';
        } else if (!this.validateEmail(this.email)) {
          this.emailError = '邮箱格式不正确';
        } else {
          this.emailError = '';
          await this.sendEmailVerification(this.email);
          this.$toast.success('验证码已发送至您的邮箱');
          this.$router.push({ name: 'MVerifyCode', query: { email: this.email } });
        }
      } else if (this.activeTab === 1) {
        // 验证手机号
        if (!this.phone) {
          this.phoneError = '请输入手机号';
        } else if (!this.validatePhone(this.phone)) {
          this.phoneError = '手机号格式不正确';
        } else {
          this.phoneError = '';
          // 处理手机号验证逻辑
          console.log('Phone:', this.phone);
        }
      } else if (this.activeTab === 2) {
        // 验证 Telegram 用户名
        if (!this.telegram) {
          this.telegramError = '请输入 Telegram 用户名';
        } else if (!this.validateTelegram(this.telegram)) {
          this.telegramError = 'Telegram 用户名格式不正确';
        } else {
          this.telegramError = '';
          // 处理 Telegram 用户名验证逻辑
          console.log('Telegram:', this.telegram);
        }
      }
    },
    async sendEmailVerification() {
      // 模拟发送邮箱验证码的函数，可以替换为实际的 API 请求
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true); // 模拟成功发送验证码
        }, 1000);
      });
    },
    goToLogin() {
      this.$router.push({ name: 'MLogin' });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  background-color: $main-bg-color;
  height: 100%;
  box-sizing: border-box;

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .van-nav-bar {
    width: 100%;
    background-color: transparent;
    color: $main-font-color;
  }

  .custom-tabs {
    width: 100%;
    margin: 20px 0;
    background-color: $nav-bg-color;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 25px;

    .van-tabs__wrap {
      border-bottom: 1px solid $border-color;
      margin-bottom: 20px;
      .van-tabs__nav {
        background-color: transparent;
        .van-tabs__line {
          background-color: transparent;
        }
      }
    }

    .van-tab {
      border-bottom: 1px solid $border-color;
      padding: 10px 15px;
      background-color: $nav-bg-color;
      border-radius: 8px;
      color: $secondary-font-color;

      &.van-tab--active {
        background-color: $primary-color;
        color: $black;
        border-bottom: 2px solid $primary-color;
      }
    }

    .van-tabs__content {
      padding: 5px 15px;
    }
  }

  .custom-field {
    width: 100%;
    background-color: $input-bg-color;
    color: $main-font-color;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    margin-bottom: 15px;

    &:hover {
      border: 1px solid $button-hover-color;
      color: $button-hover-color;
    }

    .van-field__control {
      color: $main-font-color;
    }

    &::placeholder {
      color: $placeholder-color;
    }
  }

  .custom-button {
    width: 100%;
    margin-top: 30px;
    background: linear-gradient(to right, $button-bg-gradient-start, $button-bg-gradient-end);
    border-radius: $button-border-radius;
    box-shadow: $shadow-deep;
    border: none;
    font-weight: bold;
    color: $white;
    height: 48px;
  }

  .login-link {
    margin-top: 20px;
    color: $secondary-font-color;

    span {
      color: $primary-color;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
