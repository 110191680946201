<template>
  <div class="home">
    <el-container class="container">
      <el-main class="main-content">
        <!-- 轮播、Top5 和开发者选择组件 -->
        <banner />
        <top5 />
        <DeveloperSelection />
        <!-- 使用 MainContentArea 组件 -->
        <main-content-area/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Banner from '@/components/desktop/Banner.vue';
import Top5 from '@/components/desktop/Top5.vue';
import DeveloperSelection from '@/views/desktop/home/DeveloperSelection.vue';
import MainContentArea from './MainContentArea.vue'; // 引入抽离出的组件

export default {
  name: 'PHome',
  components: {
    Banner,
    Top5,
    DeveloperSelection,
    MainContentArea, // 注册组件
  },
  data() {
    return {
      games: [],
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.home {
  font-family: Poppins, Poppins, sans-serif;
  color: $main-font-color;
}

.container {
  display: flex;
  min-height: calc(100vh - 64px);
}
</style>
