export default {
  vanButton: {
    confirm: '确认',
    cancel: '取消'
  },
  vanCell: {
    title: '标题',
    content: '内容'
  },
  navBar:{
    header:{
      home:'首页',
      service:'服务',
      aboutUs:'关于我们',
      contactUs:'联系我们',
      partners:'合作伙伴'
    },
    mHeader:{
      partners:'合作伙伴',
      service:'我们的服务',
      contact:'联系我们',
    }
  },
  mobile:{
    home:{
      popularAreas:'热门区域',
      popularGames:'最受欢迎的游戏',
      gameDevelopers:'精选的游戏厂商',
      gameDevelopment:'游戏开发厂商',
      developerList:'开发厂商列表',
      games:'款游戏',
      noMore:'没有更多了~',
      loadingMore:'加载更多...',
      loading:'加载中...',
      loadingError:'无法获取游戏链接，请稍后再试。',
      realTProtocol:'实时协议',
      speed:'速度',
      rtp:'RTP',
      rates:'费率',
      platformRecommendations:'平台推荐',
      phone:'电话',
      email:'邮箱',
      address:'地址',
      searchReson:'搜索结果为',
      datas:'条数据。'
    },
    about: {
      oneStop: '一站式',
      gambling: '博彩游戏接入',
      gaming: '游戏',
      gamePlusDescription: `GAME PLUS，一站式API解决方案提供商，专注于博彩和娱乐系统`,
      gPlusAPIs: 'G-Plus API',
      fastAndFlexible: '极快且灵活的接入体验',
      gPApiDescription: `<p>GAME PLUS，一站式API“集成”方案，解决博彩娱乐包网系统之API的供应商，近十年来我们整合游戏包括真人娱乐，电子游戏，体育博弈产品之供应商强强合作，为广大客户提供最优质的原厂API之集成平台服务。
        <p /> <p>GAME PLUS致力于为客户提供定制化的服务。我们的服务涵盖了从AP对接到运营全程的追踪，深入了解客户需求，制定最优的产品集成方案。</p><p>我们为各游戏厅主提供全面的技术支持、运营支持和服务过程控制，以帮助他们创造更佳的利益。</p>`,
      ourService: '我们的服务',
      games: '爆款游戏产品',
      stayTuned: '敬请期待更多游戏',
      efficientTimeSaving: '高效省时',
      ampleResources: '只需与GamePlus进行一次商务技术对接，节省大量时间，提高运营效率。',
      integratedAPI: '聚合接口',
      apiDescription: '仅需一个G-PLUS API聚合接口，打通全部的游戏厂商，无须逐个对接厂商。',
      liquidFunds: '资金灵活',
      costEffective: 'GamePlus提供充值共用额度功能。只需一次充值，即可畅享平台上的所有产品，无需再为每个厂家单独缴纳保证金',
      accessModes: '多种接入模式',
      accessModesDescription: 'GamePlus提供API接口与H5自选2种接入模式，满足不同合作伙伴的需求。',
      safeProfessional: '安全专业',
      safeProfessionalDescription: 'GamePlus拥有专业的技术团队和丰富的行业经验，保障平台安全稳定运行，提供完善的安全保障体系，保护合作伙伴和用户利益。',
      popularProviders: '热门游戏厂商',
      asia: '亚洲',
      europe: '欧洲',
      america: '美国',
      africa: '非洲',
      clientsIn95Countries: '客户遍布95+个国家',
      supportedCurrencies: '支持的货币',
      brandLogos: '品牌标识'
    },
    contact: {
      commitment: '我们致力于建立全球合作伙伴关系',
      yourName: '您的姓名',
      enterYourName: '请输入您的姓名',
      enterYourEmail: '请输入您的邮箱',
      preferredMessenger: '首选通讯方式',
      pleaseChoose: '请选择',
      message: '信息',
      briefSummary: '请提供简要说明',
      submit: '提交',
      contact: '联系',
      formDescription: '如果您想了解更多关于我们的服务和解决方案，请填写表单，我们的专家将很快与您联系。'
    },
    partner: {
      partnerHeader: '合作伙伴',
      partnerDescription: `Gaming Plus与专业合作伙伴合作，为玩家提供激动人心、振奋人心且备受期待的游戏。`,
      collaboration: '一次合作，终身伙伴，共同成长'
    }
  },
  desktop:{
    home:{
      popular:'热门游戏',
    }
  },
  publice:{
    more:'更多',
    todos:'所有',
    keyWords:'请输入搜索关键词',
    search:'搜索',
    gameType:'游戏类型',
    filters:'筛选条件',
    searchGames:'搜索游戏',
    popularity:'选择欢迎程度',
    all:'全部',
    hot:'热门',
    high:'高',
    med:'中',
    low:'低',
    type:'类型',
    action:'动作',
    adv:'冒险',
    strat:'策略',
    language:'选择语言',
    product:'选择产品',
    cn:'中文',
    en:'英文',
    oth:'其他',
    disclaimer:'免责声明',
    privacy:'隐私政策',
    copyright:'版权声明',
    allRights:'保留所有权利。',
    footer: '©2024 Gaming Plus. 保留所有权利。隐私政策Cookies'
  },
  gameType:{
    slot:'老虎机',
    fishing:'捕鱼游戏',
    mini:'小游戏',
    poker:'棋牌游戏',
    live:'真人场景',
    sports:'体育竞技',
    crypto:'区块链游戏',
    esports:'电子竞技',
    lottery:'彩票游戏',
    special:'特色游戏',
  },
  // 其他自定义的国际化内容
  companyProfile:{
    content: `GAME PLUS，一站式API“集成”方案，解决博彩娱乐包网系统之API的供应商，近十年来我们整合游戏包括真人娱乐，电子游
戏，体育博弈产品之供应商强强合作，为广大客户提供最优质的原厂API之集成平台服务。
GAME PLUS致力于为客户提供定制化的服务。我们的服务涵盖了从API对接到运营全程的追踪，深入了解客户需求，制定最优的
产品集成方案。我们为各游戏厅主提供全面的技术支持、运营支持和服务过程控制，以帮助他们创造更佳的利益。`
  }
};