import { isMobile } from '../utils';
export default {
  data () {
    return {
      isMobile: isMobile()
    };
  },
  created () {
    window.addEventListener('resize', this.checkIsMobile);
  },
  destroyed () {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  methods: {
    checkIsMobile () {
      const mobile = isMobile();
      if (this.isMobile !== mobile) {
        this.isMobile = mobile;
      }
    }
  }
};