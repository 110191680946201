export default {
  vanButton: {
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  vanCell: {
    title: 'Title',
    content: 'Content'
  },
  navBar: {
    header: {
      home: 'Home',
      service: 'Service',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      partners: 'Partner'
    },
    mHeader: {
      service: 'Our Services',
    }
  },
  mobile: {
    home: {
      popularAreas: 'Popular Areas',
      popularGames: 'Top Games',
      gameDevelopers: 'Featured Developers',
      gameDevelopment: 'Dev Companies',
      developerList: 'Dev List',
      games: 'Games',
      noMore: 'No More ~',
      loadingMore: 'Loading More...',
      loading: 'Loading...',
      loadingError: 'Unable to retrieve the game link, please try again later.',
      realTProtocol: 'Real-time',
      speed: 'Speed',
      rtp: 'RTP',
      rates: 'Rates',
      platformRecommendations: 'Platform Rec',
      phone: 'Phone',
      email: 'Email',
      address: 'Addr',
      searchReson: 'The search results are',
      datas: 'data entries.'
    },
    about: {
      oneStop: 'ONE-STOP',
      gambling: 'GAMBLING',
      gaming: 'GAMING',
      gamePlusDescription: `GAME PLUS, a one-stop API solution provider for gambling and entertainment systems`,
      gPlusAPIs: 'G-Plus APIs',
      fastAndFlexible: 'EXTREMELY FAST AND FLEXIBLE ACCESS EXPERIENCE',
      gPApiDescription: `<p>GAME PLUS: A One-Stop API "Integration" Solution
For nearly a decade, GAME PLUS has been the leading provider of API solutions for iGaming systems, integrating top-tier suppliers across various gaming products such as live casino, electronic games, and sports betting. We collaborate with the strongest partners in the industry to offer our clients the highest quality direct integration services through an all-in-one platform.
 <p /> <p>GAME PLUS is dedicated to providing customized services tailored to our clients' needs. Our offerings range from API integration to full operational tracking, ensuring a deep understanding of client requirements to design the most optimal product integration solutions.</p><p>We provide comprehensive technical support, operational assistance, and service process management to help gaming operators maximize their profitability.</p>`,
      ourService: 'OUR SERVICE',
      games: 'Popular Game Products',
      stayTuned: 'Stay tuned for more games',
      efficientTimeSaving: 'Efficient and Time-saving',
      ampleResources: 'With just one business and technical integration with GamePlus, you can save a significant amount of time and enhance operational efficiency.',
      integratedAPI: 'Aggregated API',
      apiDescription: 'Only one G-PLUS API is needed to connect with all game publishers. No need for individual integrations with each publisher.',
      liquidFunds: 'Flexible Funds',
      costEffective: 'GamePlus offers a shared balance feature for top-ups. With just one top-up, you can enjoy all the products on the platform without needing to pay separate deposits for each publisher.',
      accessModes: 'Multiple Access Modes',
      accessModesDescription: 'GamePlus provides two access modes: API integration and H5 self-selection, meeting the needs of different partners.',
      safeProfessional: 'Safe and Professional',
      safeProfessionalDescription: 'GamePlus has a professional technical team and extensive industry experience, ensuring the platform operates securely and stably. It offers a comprehensive security system to protect the interests of both partners and users.',

      // New Translations added
      popularProviders: 'Popular Game Providers',
      asia: 'Asia',
      europe: 'Europe',
      america: 'United States',
      africa: 'Africa',
      clientsIn95Countries: 'Clients in 95+ countries',
      supportedCurrencies: 'Supported Currencies',
      brandLogos: 'Brand Logos'
    },
    contact: {
      commitment: 'We Are Committed To Establishing Partnerships Worldwide',
      yourName: 'Your name',
      enterYourName: 'Enter your name',
      enterYourEmail: 'Enter your email',
      preferredMessenger: 'Preferred messenger',
      pleaseChoose: 'Please choose',
      message: 'Message',
      briefSummary: 'Please provide a brief summary.',
      submit: 'Submit',
      contact: 'Contact',
      formDescription: 'If you want to learn more about our services and solutions, complete the form and one of our experts will be in touch shortly.'
    },
    partner: {
      partnerHeader: 'PARTNER',
      partnerDescription: `Gaming Plus teams up with professional partners to provide gamers with exciting, exhilarating, and highly anticipated games.`,
      collaboration: 'One Collaboration, Lifelong Partnership, Growing Together'
    }
  },
  desktop: {
    home: {
      popular: 'Popular',
    }
  },
  publice: {
    more: 'More',
    todos: 'ALL',
    keyWords: 'enter search keywords.',
    search: 'Search',
    gameType: 'Game Type',
    filters: 'Filters',
    searchGames: 'Search Games',
    popularity: 'Popularity',
    all: 'All',
    hot: 'Hot',
    high: 'High',
    med: 'Med',
    low: 'Low',
    type: 'Type',
    action: 'Action',
    adv: 'Adv',
    strat: 'Strat',
    language: 'Language',
    product: 'Product',
    cn: 'CN',
    en: 'EN',
    oth: 'Oth',
    disclaimer: 'Disc',
    privacy: 'Privacy',
    copyright: 'Copyright',
    allRights: 'All Rights.',
    footer: '©2024 Gaming Plus. All rights reserved. Privacy Policy Cookies'
  },
  gameType: {
    slot: 'SLOT',
    fishing: 'FISHING',
    mini: 'MINI',
    poker: 'POKER',
    live: 'LIVE',
    sports: 'SPORTS',
    crypto: 'CRYPTO',
    esports: 'ESPORTS',
    lottery: 'LOTTERY',
    special: 'SPECIAL',
  },
  companyProfile: {
    content: `GAME PLUS, a one-stop API "integration" solution provider for gambling and entertainment systems, has 
integrated games including live entertainment, video games, and sports betting products from top suppliers 
over the past decade, offering customers the highest quality original API integration platform services.`
  }
};
