<template>
  <div class="game-detail">
    <el-container class="container">
      <!-- 左侧内容区域 -->
      <el-aside class="left-panel" width="60%">
        <h1 class="main-title">{{ game.title }}</h1>
        <el-carousel :interval="5000" arrow="always" class="game-carousel">
          <el-carousel-item v-for="media in game.media" :key="media.id">
            <img :src="media.url" alt="Game media" />
          </el-carousel-item>
        </el-carousel>

        <!-- 游戏描述 -->
        <div class="game-description section">
  <h2 class="section-title">游戏描述</h2>
  <el-divider></el-divider>

  <!-- 游戏概述 -->
  <p>{{ game.description.overview }}</p>

  <!-- 游戏详细信息 -->
  <div class="game-info-details">
    <el-row :gutter="20">
      <!-- 左侧信息 -->
      <el-col :span="12">
        <div class="info-block">
          <h4 class="subsection-title">发行日期：</h4>
          <p>{{ game.description.releaseDate }}</p>
        </div>
        <div class="info-block">
          <h4 class="subsection-title">开发者：</h4>
          <p>{{ game.description.developer }}</p>
        </div>
      </el-col>

      <!-- 右侧信息 -->
      <el-col :span="12">
        <div class="info-block">
          <h4 class="subsection-title">游戏平台：</h4>
          <p>{{ game.description.platforms.join(', ') }}</p>
        </div>
        <div class="info-block">
          <h4 class="subsection-title">游戏类型：</h4>
          <p>{{ game.description.genre }}</p>
        </div>
      </el-col>
    </el-row>

    <!-- 主要特色 -->
    <el-row>
      <el-col :span="24">
        <h4 class="subsection-title">主要特色：</h4>
        <ul class="feature-list">
          <li v-for="feature in game.description.keyFeatures" :key="feature">{{ feature }}</li>
        </ul>
      </el-col>
    </el-row>
  </div>
</div>



        <!-- 游戏功能 -->
        <div class="game-features section">
          <h3 class="section-title">游戏功能</h3>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :span="12" v-for="(feature, index) in game.features" :key="index" class="feature-item">
              <el-card shadow="hover" class="feature-card">
                <div class="feature-icon">
                  <i :class="feature.icon"></i>
                </div>
                <div class="feature-details">
                  <h4 class="subsection-title">{{ feature.name }}</h4>
                  <p>{{ feature.description }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-aside>

      <!-- 右侧内容区域 -->
      <el-main class="right-panel">
        <div class="game-screenshots section">
          <h3 class="section-title">游戏截图</h3>
          <el-divider></el-divider>
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="screenshot in game.screenshots" :key="screenshot.id">
              <img :src="screenshot.url" alt="Screenshot" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="game-comments section">
          <h3 class="section-title">用户评论</h3>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :span="24" v-for="comment in game.comments" :key="comment.id">
              <comment class="game-comment">
                <template #author>
                  <strong>{{ comment.author }}</strong>
                </template>
                <template #content>
                  <p>{{ comment.content }}</p>
                </template>
                <template #actions>
                  <el-rate v-model="comment.rating" disabled></el-rate>
                  <el-button type="text" @click="handleReply(comment)">回复</el-button>
                </template>
              </comment>
            </el-col>
          </el-row>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Comment from '@/components/desktop/Comment.vue';

export default {
  name: 'GameDetail',
  components: {
    Comment
  },
  data() {
    return {
      game: {
        title: JSON.parse(this.$route.query.info).name,
        description: {
          overview: '这是一款极具深度的角色扮演游戏，结合了战斗、探索和丰富的故事情节，玩家将扮演主角展开一段奇幻的冒险。',
          releaseDate: '2024年3月15日',
          developer: 'XYZ工作室',
          platforms: ['PC', 'PlayStation', 'Xbox', 'Nintendo Switch'],
          genre: '角色扮演 (RPG)',
          keyFeatures: [
            '开放世界：广袤的世界等待你的探索',
            '多分支剧情：你的选择将影响游戏的结局',
            '自定义角色：从外观到能力，全方位定制你的英雄',
            '动态天气系统：真实的环境变化体验',
            '多人模式：与好友一同探险或对战'
          ]
        },
        media: [
          { id: 1, url: require('@/assets/images/game/default.jpg') },
          { id: 2, url: require('@/assets/images/game/default.jpg') },
        ],
        features: [
          {
            name: '多人合作',
            description: '支持多名玩家进行合作游戏。',
            icon: 'el-icon-user-solid',
          },
          {
            name: '丰富剧情',
            description: '享受沉浸式的游戏故事和角色发展。',
            icon: 'el-icon-s-platform',
          },
          {
            name: '多种结局',
            description: '根据你的选择，解锁不同的游戏结局。',
            icon: 'el-icon-connection',
          },
          {
            name: '自定义角色',
            description: '自由创建和定制你的游戏角色。',
            icon: 'el-icon-setting',
          }
        ],
        screenshots: [
          { id: 1, url: require('@/assets/images/game/default.jpg') },
          { id: 2, url: require('@/assets/images/game/default.jpg') },
        ],
        comments: [
          {
            id: 1,
            author: 'User 1',
            content: '这款游戏非常棒！',
            rating: 5,
            replies: [
              { id: 101, author: 'Dev', content: '感谢你的支持！' }
            ]
          },
          {
            id: 2,
            author: 'User 2',
            content: '整体不错，但有一些bug。',
            rating: 3,
            replies: []
          },
        ],
      }
    };
  },
  methods: {
    handleReply(comment) {
      console.log('回复:', comment);
    }
  }
};
</script>

<style lang="scss" scoped>
.game-detail {
  font-family: Poppins, Poppins, sans-serif;
  background-color: $main-bg-color;
  color: $main-font-color;
  padding: 20px;
  min-height: calc(100vh - 198px);
}

.container {
  display: flex;
  height: 100%;
  background-color: $main-bg-color;
}
.left-panel,
.right-panel {
  display: flex;
  flex-direction: column;
}

.section {
  background-color: $dark-gray-bar;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.game-screenshots,
.game-comments {
  margin-bottom: 20px;
}

.feature-item,
.comment {
  margin-bottom: 20px;
}

.left-panel {
  padding: 20px;
  background-color: $dark-gray;
  color: $main-font-color;
  width: 60%;
}

.main-title {
  font-size: 36px;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 25px;
}

.section-title {
  font-size: 24px;
  font-weight: 600;
  color: $primary-color;
  margin-bottom: 20px;
  // padding-left: 10px;
  // text-align: left;
  // border-left: 4px solid $primary-color;
}

.subsection-title {
  font-size: 18px;
  font-weight: 500;
  color: $primary-color;
  margin-bottom: 10px;
}

.section {
  background-color: $dark-gray-bar;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.el-divider {
  margin: 10px 0;
  border-color: $light-border-color;
  background-color: $light-border-color;
}
.game-carousel img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.game-description p {
  font-size: 16px;
  line-height: 1.5;
  color: $light-gray;
}

.game-info-details h4 {
  color: $primary-color;
  margin-bottom: 5px;
}

.game-info-details p, ul {
  font-size: 14px;
  color: $light-gray;
  margin-bottom: 10px;
}

.game-info-details ul {
  list-style-type: disc;
  padding-left: 20px;
}

.feature-item {
  margin-bottom: 20px;
}

.feature-item .el-card {
  background-color: $card-bg-color;
  color: $main-font-color;
  border-radius: 8px;
  border-color: $light-border-color;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
}

.feature-item .feature-icon i {
  font-size: 36px;
  color: $primary-color;
  margin-bottom: 10px;
}

.right-panel {
  width: 40%;
  padding: 20px;
  background-color: $dark-gray;
  color: $main-font-color;
}

.right-panel .section {
  background-color: $card-bg-color;
  border-radius: 8px;
  margin-bottom: 20px;
}

.game-screenshots {
  max-height: 400px; /* 设置游戏截图模块的最大高度 */
  overflow-y: auto;  /* 如果内容超过最大高度，启用垂直滚动 */
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
    max-height: 300px; /* 限制每张图片的最大高度 */
    object-fit: cover; /* 确保图片在限定区域内保持比例 */
    border-radius: 8px;
  }
}


.comment {
  padding: 15px;
  background-color: $dark-gray-bar;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px $shadow-color;
}

.comment-author {
  font-weight: bold;
  color: $primary-color;
}

.comment-content {
  margin-top: 5px;
  font-size: 14px;
  color: $light-gray;
}

.comment-actions {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .el-rate {
    margin-right: 10px;
  }
  .el-button {
    color: $highlight-color;
    &:hover {
      color: $dark-active-bg-color;
    }
  }
}
.game-description {
  background-color: $dark-gray-bar;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 30px;
  color: $light-gray;

  .section-title {
    font-size: 28px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 20px;
    padding-left: 10px;
    border-left: 4px solid $primary-color;
  }

  .game-info-details {
    margin-top: 20px;

    .info-block {
      margin-bottom: 20px;
    }

    h4.subsection-title {
      font-size: 18px;
      font-weight: 500;
      color: $primary-color;
      margin-bottom: 5px;
    }

    p {
      font-size: 15px;
      color: $light-gray;
      margin-bottom: 15px;
    }
  }

  .feature-list {
    list-style-type: disc;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      color: $main-font-color;
    }
  }

  .el-divider {
    margin: 20px 0;
    border-color: $light-border-color;
    background-color: $light-border-color;
  }
}
.game-description {
  background-color: $dark-gray-bar;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 30px;
  color: $light-gray;
  text-align: left; /* 文字左对齐 */

  .section-title {
    font-size: 28px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 20px;
    padding-left: 10px;
    border-left: 4px solid $primary-color;
    text-align: left; /* 标题左对齐 */
  }

  .game-info-details {
    margin-top: 20px;

    .info-block {
      margin-bottom: 20px;
    }

    h4.subsection-title {
      font-size: 18px;
      font-weight: 500;
      color: $primary-color;
      margin-bottom: 5px;
      text-align: left; /* 子标题左对齐 */
    }

    p {
      font-size: 15px;
      color: $light-gray;
      margin-bottom: 15px;
      text-align: left; /* 段落左对齐 */
    }
  }

  .feature-list {
    list-style-type: disc;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      color: $main-font-color;
      text-align: left; /* 列表左对齐 */
    }
  }

  .el-divider {
    margin: 20px 0;
    border-color: $light-border-color;
    background-color: $light-border-color;
  }
}

</style>
