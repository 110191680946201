<template>
  <div class="contact-page mt-0">
    <!-- 骨架屏在页面加载时显示 -->
    <el-skeleton :rows="5" animated class="empty-temp" v-if="!isContentLoaded">
      <template #template>
        <!-- 骨架屏模拟 Banner -->
        <div class="skeleton-banner"></div>

        <!-- 骨架屏模拟 Form -->
        <div class="skeleton-section">
          <div class="skeleton-title"></div>
          <div class="skeleton-field"></div>
          <div class="skeleton-field"></div>
          <div class="skeleton-field"></div>
          <div class="skeleton-field"></div>
          <div class="skeleton-button"></div>
        </div>
      </template>
    </el-skeleton>

    <!-- 页面真实内容在加载完成后显示 -->
    <transition name="fade">
      <div v-if="isContentLoaded" class="contact-page1">
        <!-- 头部Banner -->
        <div class="banner ">
          <van-image :src="bannerImage" fit="cover" width="100%" height="115px" loading="lazy" />
        </div>

        <!-- 标题和描述 -->
        <div class="title-section">
          <h1>{{$t('mobile.contact.contact')}}</h1>
          <p>{{$t('mobile.contact.commitment')}}</p>
        </div>

        <div class="content">
          <van-image v-if="alarmImage" class="absolute alarm" :src="alarmImage" loading="lazy"></van-image>
          <van-image v-if="moneyImage" class="absolute money" :src="moneyImage" loading="lazy"></van-image>
          <van-image v-if="money1Image" class="absolute money1" :src="money1Image" loading="lazy"></van-image>

          <!-- 联系表单 -->
          <van-form @submit="onSubmit" class="contact-form">
            <van-field
              v-model="formData.name"
              :label="$t('mobile.contact.yourName')"
              :placeholder="$t('mobile.contact.enterYourName')"
              required
              :error-message="errors.name"
            />

            <van-field
              v-model="formData.email"
              :label="$t('mobile.home.email')"
              :placeholder="$t('mobile.contact.enterYourEmail')"
              type="email"
              required
              :error-message="errors.email"
            />

            <van-field
              v-model="formData.messenger"
              :label="$t('mobile.contact.preferredMessenger')"
              :placeholder="$t('mobile.contact.pleaseChoose')"
              required
              :error-message="errors.messenger"
              readonly
              @click="showMessengerPicker = true"
            />
            <van-popup v-model="showMessengerPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="messengerOptions"
                @confirm="onMessengerConfirm"
                @cancel="showMessengerPicker = false"
              />
            </van-popup>

            <van-field
              v-model="formData.message"
              :label="$t('mobile.contact.message')"
              type="textarea"
              rows="4"
              :placeholder="$t('mobile.contact.briefSummary')"
              required
              :error-message="errors.message"
            />

            <!-- 提交按钮 -->
            <div class="submit-button">
              <van-button round block native-type="submit">
                {{$t('mobile.contact.submit')}}
              </van-button>
            </div>
          </van-form>

          <!-- 联系信息 -->
          <div class="contact-info">
            <h2>{{$t('navBar.header.contactUs')}}</h2>
            <p class="px-4">
              {{$t('mobile.contact.formDescription')}}
            </p>

            <!-- 社交媒体 -->
            <div class="social-icons px-4">
              <van-button 
                plain
                round  
                class="btn-telegram text-xs"
                size="large" 
                :url="commonConfig.telegram"
                ><i class="iconfont icon-telegram1 mr-2"></i> 
                <span class="text-xs">Telegram </span> 
              </van-button>
                <van-button 
                class="btn-whatsapp"
                size="large text-xs" 
                plain
                round  
                :url="commonConfig.whatsapp"
                ><i class="iconfont icon-whatsapp1 mr-2"></i> 
                <span class="text-xs">WhatsApp</span></van-button>
              <!-- <a class="icon btn-elmain" href="#">
                <van-icon name="envelop-o" />
              </a>
              <a class="icon btn-telegram" :href="commonConfig.telegram">
                <i class="iconfont icon-telegram1"></i>
              </a>
              <a class="icon btn-whatsapp" :href="commonConfig.whatsapp">
                <i class="iconfont icon-whatsapp1"></i>
              </a> -->
            </div>

            <!-- 底部图片 -->
            <div class="footer-image">
              <van-image height="164" width="115" :src="mascotImage" loading="lazy" />
            </div>
          </div>
        </div>

        <FootInfo />
      </div>
    </transition>
  </div>
</template>

<script>
import FootInfo from '@/components/mobile/FootInfo.vue';

export default {
  name: 'ContactPage',
  components: { FootInfo },
  data() {
    return {
      isContentLoaded: false, // 用于处理过渡效果
      formData: {
        name: '',
        email: '',
        messenger: '',
        message: '',
      },
      errors: {
        name: '',
        email: '',
        messenger: '',
        message: '',
      },
      showMessengerPicker: false,
      messengerOptions: ['WhatsApp', 'Telegram', 'WeChat', 'Messenger'],
      bannerImage: require('@/assets/images/contact/contact_banner.jpg'),
      alarmImage: require('@/assets/images/contact/contact_alarm.webp'),
      moneyImage: require('@/assets/images/contact/contact_money.png'),
      money1Image: require('@/assets/images/contact/contact_money1.png'),
      mascotImage: require('@/assets/images/contact/contact_mascot.webp'),
    };
  },
  computed: {
    commonConfig() {
      return this.$store.getters['common/commonConfig'];
    },
  },
  mounted() {
   // 页面加载完成后，展示内容
   this.$nextTick(() => {
      this.isContentLoaded = true;
    });
  },
  methods: {
    onSubmit() {
      this.errors = { name: '', email: '', messenger: '', message: '' };

      if (!this.formData.name) {
        this.errors.name = 'Name is required';
      }
      if (!this.formData.email) {
        this.errors.email = 'Email is required';
      }
      if (!this.formData.messenger) {
        this.errors.messenger = 'Messenger is required';
      }
      if (!this.formData.message) {
        this.errors.message = 'Message is required';
      }

      if (!this.errors.name && !this.errors.email && !this.errors.messenger && !this.errors.message) {
        console.log('Form submitted', this.formData);
      }
    },
    onMessengerConfirm(value) {
      this.formData.messenger = value;
      this.showMessengerPicker = false;
    }
  }
};
</script>
<style scoped lang="scss">
/* 骨架屏样式 */
.skeleton-banner {
  width: 100%;
  height: 115px;
  background: $empty-bg-color;
  border-radius: 16px;
  margin-bottom: 20px;
}

.skeleton-section {
  margin-bottom: 30px;
  .skeleton-title {
    height: 20px;
    width: 150px;
    background: $empty-bg-color;
    border-radius: 16px;
    margin: 0 auto 10px;
  }
  .skeleton-field {
    height: 34px;
    width: 100%;
    background: $empty-bg-color;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  .skeleton-button {
    width: 100%;
    height: 40px;
    background: $empty-bg-color;
    border-radius: 8px;
  }
}

/* 真实内容的样式 */
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  background-color: $main-bg-color;
  min-height: 100vh; /* 确保页面的最小高度 */
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  overflow-x: hidden; /* 防止水平滚动 */
}

.banner {
  width: 100%;
  height: 115px;
  border-radius: 16px;
  overflow: hidden;
}

.content {
  background: #121213;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  padding: 20px 0;
}

.van-image.absolute {
  position: absolute;
  object-fit: cover;
}

.alarm {
  top: -39px;
  right: -15px;
  height: 102px;
  width: 102px;
}

.money {
  top: 300px;
  right: -20px;
  height: 100px;
  width: 60px;
}

.money1 {
  top: 67px;
  left: -18px;
  width: 45px;
  height: 95px;
}

.title-section {
  text-align: center;
  margin: 20px 0;
}

.title-section h1 {
  font-weight: 900;
  font-size: 32px;
  margin-top: 0;
}

.title-section p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  margin-top: 10px;
}

::v-deep.contact-form {
  width: 100%;
  margin-top: 0px;
  padding: 0 10px 10px;
  background-color: $main-bg-color;
  border-radius: 16px;
  .van-field__label {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: $white;
    margin-bottom: 12px;
  }
  .van-cell {
    flex-direction: column;
    background-color: transparent !important;
    &::after {
      border-width: 0;
    }
    .van-field__control {
      background-color: rgba(255, 255, 255, 0.04) !important;
      border-radius: 8px;
      min-height: 34px;
      padding: 0 12px;
    }
  }
}

.submit-button {
  margin-top: 20px;
  .van-button {
    background-color: $more-font-color;
    border-width: 0;
    .van-button__text {
      font-size: 16px;
    }
  }
}

.contact-info {
  margin: 30px 0;
  text-align: center;
}

.contact-info h2 {
  font-weight: bold;
  font-size: 24px;
}

.contact-info p {
  font-size: 10px;
  color: $default-font-color;
  margin: 10px 0;
}
.van-button{
  //background: #f5d8ae;
  border-style: solid;
  border-width: 1px;
border-color: transparent;
  border-radius: 15px;
}
  // .van-button:focus, .van-button:hover {
  //   background: #e6a23c;
  //   border-color: #e6a23c;
  //   .van-button__content{
  //     color: #fff;
  //   }
        
  // }
  .btn-telegram {
  background-color: #0088cc;
  color: #fff;
}

.btn-whatsapp {
  background-color: #25d366;
  color: #fff;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;

  .btn-elmain {
    color: rgba(18, 169, 255);
  }
  .btn-telegram {
    color: #0088cc;
  }
  .btn-whatsapp {
    color: #25d366;
  }

  .van-icon {
    font-size: 22px;
  }
  .iconfont {
    font-size: 18px;
    
  }
}

.footer-image {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>

