<template>
  <div class="not m-card">
    <van-nav-bar title="通知" left-arrow @click-left="$router.go(-1)" />
    <div class="content">
      <van-list v-model="loading" :finished="finished">
        <van-cell 
          v-for="(notification, index) in notifications" 
          :key="index" 
          @click="openNotificationDetail(notification)">
          <div class="notification-item">
            <van-badge :content="!notification.read ? '未读' : null">
              <span class="notification-title" :class="{ unread: !notification.read }">
                {{ notification.title }}
              </span>
            </van-badge>
            <span class="notification-description">{{ notification.content }}</span>
            <span class="notification-date">{{ notification.date }}</span>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationsS',
  data() {
    return {
      notifications: [
        { id: 1, title: '通知1', date: '2023-01-01', content: '通知1的详细内容...', read: false },
        { id: 2, title: '通知2', date: '2023-01-02', content: '通知2的详细内容...', read: true },
        { id: 3, title: '通知3', date: '2023-01-03', content: '通知3的详细内容...', read: false }
      ],
      loading: false,
      finished: true
    };
  },
  methods: {
    openNotificationDetail(notification) {
      notification.read = true; // 标记为已读
      this.$router.push({ 
        name: 'NotificationDetail', 
        params: { id: notification.id },
        query: { notification: JSON.stringify(notification) }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vant-override.scss';

.not {
  height: 100%;
}

.content {
  background-color: transparent;
  padding: 16px 0;
  margin: 16px 0;
}

::v-deep.van-list {
  width: 95%;
  margin: 0 auto;
  border-radius: 15px;
  background-color: transparent !important;

  .van-cell {
    margin-bottom: 12px;
    background-color: $dark-gray-bar;
    text-align: left;
    padding: 16px 15px;
    border-radius: 8px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      border-bottom-width: 0 !important;
    }
  }

  .van-cell:last-child::after {
    border-bottom-width: 0 !important;
  }
}

::v-deep.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  color: $white;
  .van-badge--fixed{
    top: -8px;
    border-width: 0;
    padding: 3px;
    background-color: $orange-gradient-start;
  }
}

.notification-title {
  font-weight: bold;
  flex: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.unread {
    color: $primary-color; // 未读消息的标题颜色
  }
}

.notification-description {
  flex: 2.5;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $light-gray;
  text-align: center;
}

.notification-date {
  flex: 1;
  text-align: right;
  white-space: nowrap;
  color: $light-gray;
}
</style>
