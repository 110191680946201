<template>
  <div class="our-service">
    <div class="s-title font_20">{{$t('mobile.about.ourService')}}</div>
    <van-swipe class="service-swipe mt-0" :loop="false" width="335" height="440">
      <van-swipe-item class="swipe-item" v-for="(card, cardIndex) in serviceCardInfo" :key="cardIndex">
        <div class="service-card p-4" :class="[card.type === 'county' ? 'product' : (card.type === 'curry' ? 'hb' : '')]">
          <h3 class="service-card-title font_32" :data-target="card.number">{{card.number}}</h3>
          <p class="card-subtitle">{{$t(`${card.title}`)}}</p>

          <!-- 游戏缩略图展示 -->
          <div v-if="card.type === 'game'" class="game-thumbnails grid grid-cols-4 gap-2">
            <van-image v-for="(game, index) in card.img" :key="index" :src="getImage(`${game}.png`)" alt="游戏缩略图" class="game-thumbnail" width="60" height="60" />
          </div>

          <!-- 厂商图片展示 -->
          <div v-if="card.type === 'parten'" class="stats">
            <van-image v-for="(pt, ind) in card.img" :key="ind" :src="pt" alt="厂商图片" height="100" />
          </div>

          <!-- 客户国家展示 -->
          <div v-if="card.type === 'county'" class="stats">
            <p v-for="(county, countyIndex) in card.img" :key="countyIndex">
              <span class="dot" :style="{ backgroundColor: county.color }"></span> {{$t(county.title)}} {{county.number}}
            </p>
            <div id="product-chart" style="width: 200px; height: 150px;"></div>
          </div>

          <!-- 货币展示 -->
          <el-carousel v-if="card.type === 'curry'" :interval="2000" :autoplay="true" type="card" height="80px" arrow="never" indicatorPosition="none">
            <el-carousel-item v-for="(curry, ind) in card.img" :key="ind" class="currency-icons grid grid-cols-4 gap-4">
              <div class="swipe-center">
                <img :src="getImage('game/gold-coin.svg')" alt="currency" />
                <div class="hb-text text-lg">{{ curry }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <p class="footer-text">{{$t(`${card.footerText}`)}}</p>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { startCounting } from '@/utils/index';

export default {
  name: 'ServiceCard',
  data() {
    return {
      partners: [
        require('@/assets/images/partners/partners_img1.png'),
        require('@/assets/images/partners/partners_img2.png'),
      ],
      currencyList: [
        'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT',
        'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD',
        'CDF', 'CHF', 'CLF', 'CLP', 'CNH', 'CNY', 'COP', 'CRC', 'CUP', 'CVE', 'CYP', 'CZK', 'DEM',
        'DJF', 'DKK', 'DOP', 'DZD', 'ECS', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'FRF', 'GBP',
        'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR',
        'IEP', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'ITL', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR',
        'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL',
        'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN',
        'MXV', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK',
        'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG',
        'SEK', 'SGD', 'SHP', 'SIT', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS',
        'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'UYU', 'UZS', 'VEF',
        'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD', 'XCP', 'XDR', 'XOF', 'XPD', 'XPF', 'XPT',
        'YER', 'ZAR', 'ZMW', 'ZWL', 'VND2', 'MMK2', 'IDR2', 'LAK2',
      ],
    };
  },
  computed: {
    serviceCardInfo() {
      return [
        {
          type: 'game',
          number: '12000+',
          title: 'mobile.about.games',
          img: [
            'game/sw1.1', 'game/sw1.2', 'game/sw1.3', 'game/sw1.4', 'game/sw1.5', 'game/sw1.6', 'game/sw1.7', 'game/sw1.8', 'game/sw1.9',
            'game/sw1.10', 'game/sw1.11', 'game/sw1.12',
          ],
          footerText: 'mobile.about.stayTuned',
        },
        {
          type: 'parten',
          number: '300+',
          title: 'mobile.about.popularProviders',
          img: this.partners,
          footerText: '',
        },
        {
          type: 'county',
          number: '95+',
          title: 'mobile.about.clientsIn95Countries',
          img: [
            { title: 'mobile.about.asia', color: '#f5222d', number: '42%' },
            { title: 'mobile.about.europe', color: '#faad14', number: '30%' },
            { title: 'mobile.about.america', color: '#722ed1', number: '12%' },
            { title: 'mobile.about.africa', color: '#1890ff', number: '10%' },
          ],
          footerText: '',
        },
        {
          type: 'curry',
          number: '57+',
          title: 'mobile.about.supportedCurrencies',
          img: this.currencyList,
          footerText: 'mobile.about.brandLogos',
        },
      ];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pieChartsOption();
      startCounting('.service-card-title', 200);
    });
  },
  methods: {
    getImage(imgName) {
      return require(`@/assets/images/${imgName}`);
    },
    pieChartsOption() {
      const chartDom = document.getElementById('product-chart');
      if (!chartDom) return;  // 确保 DOM 存在
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption({
        tooltip: { trigger: 'item' },
        legend: { show: false },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 4,
              borderWidth: 2,
              color: (colors) => ['#f5222d', '#faad14', '#722ed1', '#1890ff'][colors.dataIndex],
            },
            label: { show: false, position: 'center' },
            emphasis: { label: { show: true, fontSize: 40, fontWeight: 'bold' } },
            labelLine: { show: false },
            data: [
              { value: 42, name: 'Asia' },
              { value: 30, name: 'Europe' },
              { value: 12, name: 'America' },
              { value: 10, name: 'Africa' },
            ],
          },
        ],
      });
    },
    beforeDestroy() {
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.our-service .s-title {
  font-weight: bold;
  text-align: center;
}

::v-deep.service-swipe {
  border-radius: 10px;
  margin-left: 10px;

  .van-swipe-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-card {
    background-color: #232324;
    border-radius: 16px;
    width: 310px;
    height: 345px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    &.product {
      position: relative;
    }
    #product-chart {
      position: absolute;
      top: 3.8rem;
      right: -1.8rem;
    }
  }

  .service-card-title {
    font-size: 32px;
    margin-bottom: 12px;
    color: $white;
    text-align: left;
  }

  .card-subtitle {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
  }

  .game-thumbnail {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    img{
      border-radius: 8px;
    }
  }
  .hb {
      .el-carousel__mask{
        width: 50%;
        background-color: transparent!important;
      }
      .el-carousel__item--card{
        display: flex;
        justify-content: center;
        
        .swipe-center{
          position: relative;
          .hb-text{
          position: absolute;
          top: 30%;
          left: 21%;
          font-weight: bold; /* 文字加粗 */
          color: #b18e13; /* 金色字体颜色 */
          text-shadow: 
            1px 1px 2px #a67c00, /* 文字阴影，产生金属光泽效果 */
            0 0 10px rgba(255, 255, 255, 0.5), /* 高亮的发光效果 */
            0 0 20px rgba(255, 223, 0, 0.75); /* 文字发光效果 */
          //font-family: 'Arial', sans-serif; /* 字体 */
          letter-spacing: 2px; /* 增加字母间距 */
        }
      }
      }
    }
  .footer-text {
    margin-top: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }

  .stats p {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 5px 0;
    color: #fff;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
}
</style>
