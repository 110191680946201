import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/styles/tailwind.css'; // 引入 Tailwind CSS 文件
//import '@/permission'; // permission control
import '@/assets/fonts/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//import './assets/styles/index.scss'; // 引用你的 CSS 文件
import Vant, { Locale,Skeleton} from 'vant';
import 'vant/lib/index.css';

import resizeMixin from './mixins/resizeMixin';
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementLocale from 'element-ui/lib/locale';
import enUS from 'vant/lib/locale/lang/en-US'; // 导入Vant的英文语言包
import zhCN from 'vant/lib/locale/lang/zh-CN'; // 导入Vant的中文语言包
import enMessages from './locales/en-US';
import zhMessages from './locales/zh-CN';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LazyLoad from '@/utils/lazy-load'; //懒加载
import { Lazyload } from 'vant';

Vue.use(Skeleton);

Vue.directive('lazy-load', LazyLoad);
Vue.use(Lazyload, {
  lazyComponent: true,
});

library.add(faGlobe);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import ECharts from 'vue-echarts';
import 'echarts';

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueI18n);
// 注册组件
Vue.component('v-chart', ECharts);

Vue.config.productionTip = false;
Vue.mixin(resizeMixin);



const messages = {
  'en-US': {
    ...enMessages,
    ...enLocale,
    ...enUS
  },
  'zh-CN': {
    ...zhMessages,
    ...zhLocale,
    ...zhCN
  }
};

const i18n = new VueI18n({
  locale: 'zh-CN', // 设置默认语言
  messages
});

// 配置Element UI使用i18n
ElementLocale.i18n((key, value) => i18n.t(key, value));

// 配置Vant使用i18n
Locale.use('zh-CN', zhCN);

// 动态加载字体
const loadFonts = () => {
  const language = navigator.language.toLowerCase(); // 获取浏览器语言并转换为小写
  let isChinese = false;

  if (language === 'zh-cn' || language === 'zh-sg') {
    // 简体中文
    isChinese = true;
  } else if (language === 'zh-tw' || language === 'zh-hk') {
    // 繁体中文
    isChinese = true;
  } else {
    // 非中文
    isChinese = false;
  }
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  
  if (isChinese) {
    link.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap'; // 中文字体
  } else {
    link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'; // 英文字体
  }

  document.head.appendChild(link); // 将动态创建的 link 插入到 head 中
};

loadFonts(); // 执行加载字体
export default i18n;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
