// src/api/services/user.js

import axiosInstance from '../axiosInstance';

export function login (data) {
  return axiosInstance.post('/login', data);
}

export function getUserInfo () {
  return axiosInstance.get('/index/game/game_types');
}
export function gameTypes () {
  return axiosInstance.get('/index/game/game_types');
}
export function gameList (data) {
  return axiosInstance.get(`/index/game/game_list`,{
    params:data
  }); 
}
//获取游戏链接
export function gameOpen (data) {
  return axiosInstance.get(`/index/game/open_game`,{
    params:data
  }); 
}
//排名前20的游戏
export function rankingList () {
  return axiosInstance.get(`/index/game/ranking_list`); 
}


export function gameProducts () {
  return axiosInstance.get(`/index/game/products`);
}
export function logout () {
  return axiosInstance.post('/logout');
}