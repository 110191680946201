<template> 
  <div class="search-bars">
    <h3>{{$t('publice.filters')}}</h3>
    <el-input
      v-model="localSearchQuery"
      :placeholder="$t('publice.searchGames')"
      prefix-icon="el-icon-search"
      class="search-input"
      clearable
    />
    <div class="select-group" v-for="(group, key) in localSelectGroup" :key="key">
      <label>{{ group.placeholder }}</label>
      <el-select v-model="group.modelActive" :placeholder="group.placeholder" clearable>
        <el-option
          v-for="(option, index) in group.array"
          :key="index"
          :label="option.label"
          :value="option.value"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchSidebar',
  props: {
    searchQuery: String,
    selectedPopularity: String,
    selectedType: String,
    selectedLanguage: String,
    selectedProduct: String,
  },
  computed: {
    gameTypes() {
      let storedGameType = sessionStorage.getItem('gameType');
      if (!storedGameType) {
        storedGameType = this.$store.getters['common/gameType'];
        if (storedGameType && storedGameType.length > 0) {
          sessionStorage.setItem('gameType', JSON.stringify(storedGameType));
        }
      } else {
        storedGameType = JSON.parse(storedGameType);
      }
      return storedGameType ? storedGameType.map(item => ({ label: item.list.name, value: item.list.name })) : [];
    },
    gameProducts() {
      let storedGameProduct = sessionStorage.getItem('gameProduct');
      if (!storedGameProduct) {
        storedGameProduct = this.$store.getters['common/gameProduct'];
        if (storedGameProduct && storedGameProduct.length > 0) {
          sessionStorage.setItem('gameProduct', JSON.stringify(storedGameProduct));
        }
      } else {
        storedGameProduct = JSON.parse(storedGameProduct);
      }
      return storedGameProduct ? storedGameProduct.map(item => ({ label: item.shortname, value: item.shortname })) : [];
    },
  },
  data() {
    return {
      localSearchQuery: this.searchQuery,
      localSelectGroup: {}, // 初始化为空对象
    };
  },
  watch: {
    localSearchQuery(val) {
      this.$emit('update:searchQuery', val);
    },
    'localSelectGroup.popularity.modelActive'(val) {
      this.$emit('update:selectedPopularity', val);
    },
    'localSelectGroup.type.modelActive'(val) {
      this.$emit('update:selectedType', val);
    },
    'localSelectGroup.language.modelActive'(val) {
      this.$emit('update:selectedLanguage', val);
    },
    'localSelectGroup.product.modelActive'(val) {
      this.$emit('update:selectedProduct', val);
    },
    '$i18n.locale': {
      handler() {
        this.updateSelectGroup(); // 当语言变化时，重新初始化选项组
      },
      deep: true,
    },
    gameTypes: 'updateSelectGroup', // 监听 gameTypes 变化
    gameProducts: 'updateSelectGroup', // 监听 gameProducts 变化
  },
  mounted() {
    this.loadGameData(); // 加载游戏数据
  },
  methods: {
    // 加载游戏类型和产品的初始数据
    loadGameData() {
      if (!sessionStorage.getItem('gameType') || !sessionStorage.getItem('gameProduct')) {
        // 如果 sessionStorage 没有数据，确保 Vuex 中获取到数据
        this.$store.dispatch('common/fetchCommonConfig').then(() => {
          this.updateSelectGroup();
        });
      } else {
        // 如果 sessionStorage 有数据，直接更新选项组
        this.updateSelectGroup();
      }
    },

    // 更新选择组
    updateSelectGroup() {
      this.localSelectGroup = {
        popularity: {
          modelActive: this.selectedPopularity,
          placeholder: this.$i18n.t('publice.popularity'),
          array: [
            { label: this.$i18n.t('publice.all'), value: '' },
            { label: this.$i18n.t('publice.hot'), value: '1' },
          ],
        },
        type: {
          modelActive: this.selectedType,
          placeholder: this.$i18n.t('publice.type'),
          array: [
            { label: this.$i18n.t('publice.all'), value: '' },
            ...this.gameTypes, // 使用计算属性
          ],
        },
        product: {
          modelActive: this.selectedProduct,
          placeholder: this.$i18n.t('publice.product'),
          array: [
            { label: this.$i18n.t('publice.all'), value: '' },
            ...this.gameProducts, // 使用计算属性
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
.search-bars {
  margin-top: 25px;
  padding: 10px 20px 20px;
  background-color: $dark-gray-bar; /* 搜索栏背景色 */
  border-radius: 8px;
  box-shadow: $shadow-deep;
  min-width: 150px;
  max-width: 300px;
  .el-input.is-active .el-input__inner, .el-input__inner:focus{
        border-color: $primary-color;
      }
      .el-input__inner:hover{
        border-color: $primary-color!important;
      }
}

.search-bars h3 {
  margin-bottom: 20px;
  color: $main-font-color; /* 标题字体颜色 */
}

.search-bars .el-select,
.search-bars .el-input {
  margin-bottom: 8px;
  width: 100%;
}

.select-group {
  text-align: left;

  label {
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    color: $light-gray;
  }
  .el-select {
      width: 100%;
      .el-input__inner:focus,.el-input.is-focus .el-input__inner {
        border-color: $primary-color;
      }
      .el-input__inner {
        background-color: $input-bg-color;
        border-color: $input-border-color;
        color: $input-text-color;
        &hover{
          border-color: $primary-color;
        }
      }

      .el-input__icon {
        color: $placeholder-color;
      }
    }
    
}
.el-select-dropdown__list{
      padding-bottom: 5px!important;
    }
.el-select-dropdown__wrap{
  margin-bottom: -16px!important;
  margin-right: -16px!important;
}
.el-popper[x-placement].el-select-dropdown {
  background-color: $card-bg-color;
  border: 1px solid $light-border-color;
  border-radius: 4px;

  .el-select-dropdown__item {
      color: $main-font-color;  /* 文字颜色统一 */
  }
  .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background-color: $dark-gray; /* 悬停背景色 */
    color: $primary-color; /* 悬停文字颜色 */
  }
 
}
 /* 自定义 el-dropdown 箭头小三角的颜色 */
 .el-popper[x-placement^=bottom] .popper__arrow {
      border-bottom-color: $light-border-color!important;
  }
  .el-popper[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: $light-border-color!important;/* 保持小三角内外一致 */
  }
.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: $light-border-color!important;/* 保持小三角内外一致 */
}
.el-popper[x-placement^=top] .popper__arrow::after {
  border-top-color: $light-border-color!important;/* 保持小三角内外一致 */
}
</style>
