<template>
  <div>
    <van-nav-bar
      :title="$t('navBar.header.aboutUs')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <!-- 关于我们页面内容 -->
    <p>这里是关于我们页面。</p>
  </div>
</template>

<script>
export default {
  name: 'AboutS'
};
</script>
<style lang="scss" scoped>
  
</style>
