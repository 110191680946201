<template>
  <div class="cooperation-page">
    <div class="banner">
    <van-image :src="require('@/assets/images/partners/partners_banner.jpg')"  
    width="100%"
      height="200px"
      fit="cover"/>
  </div>
  <div class="con_center">
   
  <div class="parther">
    <div class="title font_32">PARTHER</div>
    <div class="info_default font_10">
      Gaming Plus teams up with professional partners toprovide gamers with exciting, exhilarating, and highlyanticipated games.
    </div>
    <div class="compy_img">
      <van-image :src="require('@/assets/images/partners/partners_img1.png')" height="179"/>
      <van-image :src="require('@/assets/images/partners/partners_img2.png')"  height="179"/>
    </div>
  </div>
  <div class="foot_mascot">
    <van-image :src="require('@/assets/images/partners/partners_icon.png')" class="mascot_icon" height="98"/>
    <div class="mascot_info">One Collaboration, Lifelong Partnership, Growing Togethe</div>
    <van-image :src="require('@/assets/images/partners/partners_mascot.png')" class="mascot_mascot" height="122"/>
  </div>
  <div class="foot_info">
      <van-image :src="require('@/assets/logo.png')" width="140" height="24"/>
      <span class="compy_info info_default font_10">©2022 Gaming Plus.All rights reserved. Privacy PolicyCookies</span>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'CooperationPage',
  data() {
    return {
      imageArray: [
       
      ]
    };
  },
  computed: {
   
  }
};
</script>

<style lang="scss" scoped>
.cooperation-page {
  background-color: $main-bg-color;
  text-align: center;
  color: $main-font-color;
  //height: calc(100vh - 155px);
  //overflow-x: hidden; /* 禁止页面水平拖动 */
  margin: 0 auto;
  padding: 20px 20px 65px;
}
.con_center{
  width: 40%;
  margin: 20px auto;
  padding-bottom: 20px;
  border-radius: 16px 16px 16px 16px;
border: 1px solid rgba(255,255,255,0.15);
}
.banner{
  width: 100%;
  margin: 0 20px;
}
.parther{
  margin-top: 20px;
  .info_default{
    margin: 20px 0;
  }
}
.foot_mascot{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  .mascot_info{
    width: 70%;
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 20px 0;
  }
  
}
.foot_info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>