<template>
   <div class="demo-page">
    <h2 class="page-title">{{ type }}</h2>
    <main-content-area :type="type"/>
   </div>
  </template>
  
  <script>
  import MainContentArea from '@/views/desktop/home/MainContentArea.vue'; 
  export default {
    name: "PcGameDemo",
    components:{
      MainContentArea
    },
    data(){
      return{
        type:this.$route.query.type,

      }
    },
   
    computed:{
    },
    methods: {
    },
    mounted() {
    
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .demo-page{
    padding:0 20px!important;
    .page-title{
      margin-bottom: 0;
    }
  }
  </style>
  