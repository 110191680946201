import { gameList } from '@/api/services/game';

const state = {
  gameTypesData: {}, // 存储不同游戏类型的游戏列表
};

const mutations = {
  SET_GAME_TYPE_DATA(state, { type, data }) {
    // 更新或新增指定类型的游戏数据
    state.gameTypesData = {
      ...state.gameTypesData,
      [type]: data, // 使用类型作为键
    };
  },
};

const actions = {
  // 获取特定类型的游戏数据
  async fetchGameTypeData({ commit, state }, { type,pageNumber = 1, pageSize = 20 }) {
    // 如果该类型的数据已经存在，避免重复请求
    if (state.gameTypesData[type] && state.gameTypesData[type].length > 0) {
      return;
    }

    try {
      // 请求游戏列表数据
      const response = await gameList({
        game_type: type,
        pageNumber,
        pageSize,
      });

      // 提交获取到的数据
      commit('SET_GAME_TYPE_DATA', { type, data: response.data.rows || [] });
    } catch (error) {
      console.error(`Error fetching game list for type ${type}:`, error);
    }
  },
};

const getters = {
  // 返回指定类型的游戏数据
  getGameTypeData: (state) => {
    return state.gameTypesData || []; // 如果没有数据，返回空数组
  },
};

export default {
  namespaced: true, // 启用命名空间
  state,
  mutations,
  actions,
  getters,
};
