<template>
  <div class="banner-wrapper">
    <el-carousel 
      :type="gameBanner.length>=3?'card':''"
      ref="carousel"  
      :interval="5000" 
      height="380px" 
      arrow="never" 
      @change="handleChange" 
      :indicator-position="'none'"
    >
      <el-carousel-item v-for="(image, index) in gameBanner" :key="index">
        <div class="image-container">
          <el-image  
          :src="image.url" 
          :alt="image.alt" 
          class="banner-image"
          fit="cover"
          >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 自定义指示器，垂直排列 -->
    <div class="custom-indicators">
      <p class="title">{{$t('desktop.home.popular')}}</p>
      <img 
        v-for="(image, index) in gameBanner" 
        :key="index" 
        :src="image.url" 
        :alt="image.alt" 
        class="custom-indicator"
        :class="{ 'is-active': index === activeIndex }"
        @click="setActiveIndex(index)" 
      />
    </div>
  </div>
</template>


<script>
// import { gameBanners} from '@/api/home/index';
export default {
  name: 'BannerLayout',
  data() {
    return {
      banners:[],
      activeIndex: 0, // 当前激活的轮播索引
    };
  },
  computed:{
    gameBanner(){
      const res=this.$store.getters['common/gameBanner']
      return res.banner?res.banner.slice(0,5):[]
    }
  },
  mounted() {
  },
  methods: {
    handleChange (index) {
      this.activeIndex = index; // 更新当前激活的轮播索引
    },
    setActiveIndex(index) {
      this.$refs.carousel.setActiveItem(index); // 手动设置轮播项
    }
  }
};
</script>


<style lang="scss" scoped>
.banner-wrapper {
  position: relative;
  width: 100%;
  height: 381px; /* 固定容器高度以适应不同图片 */
  display: flex;
  background-color: $main-bg-color; /* 设置背景色，避免图片比例不一致时出现空白 */
  border-radius: 8px;
}

::v-deep.el-carousel {
  width: 100%;
  border: 1px solid $light-border-color;
  border-radius: 8px;
  @include box-shadow($black, 0.8);
.el-carousel__item{
    div.el-carousel__mask{
      background-color: transparent;
    }
  }
}
div.el-carousel__mask{
  border-color: transparent!important;
}
.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* 设置背景色，避免图片比例不一致时出现空白 */
  border-radius: 8px;
}

.banner-image {
  width: 100%;
  height: 100%;
  //object-fit: contain; /* 使用 contain 保证图片完整展示 */
  border-radius: 8px;
  
}
.el-image__error, .el-image__placeholder{
  background-color: transparent;
}
.custom-indicators {
  position: absolute;
  padding: 0px 12px;
  background-color: rgba($dark-gray-bar,0.95);
  top: 50%;
  right: -5px; /* 指示器放置在图片的右侧 */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column; /* 垂直排列 */
  //justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 3;
  height: 100%;
  .title{
    font-size: 20px;
    height: 20px;
    padding: 5px 8px;
    margin: 8px 0 15px;
    font-weight: 500;
    border-radius: 5px;
    color: $gold; /* 使用全局白色 */
   background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2));
  // -webkit-background-clip: text; /* 背景剪裁到文字 */
  // color: transparent; /* 使文字透明，以便展示渐变背景 */
  }
}

.custom-indicator {
  width: 100px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
  margin: 5px 0; /* 垂直方向上的间距 */
  border: 2px solid transparent;
  transition: border 0.3s ease;
  cursor: pointer;
}

.custom-indicator.is-active {
  border-color: #ffd04b; /* 激活状态的边框颜色 */
}
</style>





