// export function isMobile() {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   // 检测常见的移动设备标识
//   return /android|iPad|iPhone|iPod|opera mini|opera mobi|mobile|blackberry|windows phone/i.test(userAgent);
// }
export function isMobile () {
  return window.matchMedia("(max-width: 768px)").matches;
}
//模拟数数动态增涨效果
export function startCounting (selector, speed = 200) {
  // DOM 加载完成后执行
  document.addEventListener('DOMContentLoaded', function () {
    const counters = document.querySelectorAll(selector);

    counters.forEach(counter => {
      const updateCount = () => {
        const target = +counter.getAttribute('data-target'); // 获取目标数值
        const count = +counter.innerText; // 当前的数值
        const increment = target / speed; // 增量值

        if (count < target) {
          counter.innerText = Math.ceil(count + increment); // 每次递增
          setTimeout(updateCount, 10); // 设置每次递增的时间间隔
        } else {
          counter.innerText = target; // 最终显示目标值
        }
      };

      updateCount(); // 开始递增
    });
  });
}
