<template>
  <transition name="fade">
    <div class="cooper">
      <!-- 骨架屏在页面未加载完成时显示 -->
      <el-skeleton v-if="!isContentLoaded" class="empty-temp pt-12" animated>
        <template #template>
          <div class="skeleton-banner"></div>
          <div class="skeleton-partner">
            <div class="skeleton-title"></div>
            <div class="skeleton-info"></div>
            <div class="skeleton-developer-grid"></div>
          </div>
          <div class="skeleton-mascot"></div>
          <div class="skeleton-footer"></div>
        </template>
      </el-skeleton>

      <!-- 页面内容在数据加载完成后显示 -->
      <div v-if="isContentLoaded" class="cooperation-page">
        <!-- Banner -->
        <div class="banner ">
          <van-image :src="bannerImage" width="100%" height="115" fit="cover" loading="lazy" />
        </div>

        <!-- Partner Section -->
        <div class="partner">
          <div class="title font_32">{{$t('mobile.partner.partnerHeader')}}</div>
          <div class="info_default font_10">{{$t('mobile.partner.partnerDescription')}}</div>

          <!-- Developer Swipe Section -->
          <div class="compy_img">
            <div class="carousel-container">
              <div class="carousel-track" ref="carouselTrack">
                <!-- 每组开发者图片 -->
                <div
                  class="carousel-item"
                  v-for="(devGroup, index) in threeGroups"
                  :key="index"
                >
                  <div class="grid-item-sub" v-for="(dev, idx) in devGroup" :key="idx">
                    <van-image :src="dev.pic" class="developer-image pt-[2px] pb-[2px] px-1" fit="cover" loading="lazy" />
                  </div>
                </div>
                <!-- 复制的每组开发者图片，避免空白 -->
                <div
                  class="carousel-item"
                  v-for="(devGroup, index) in threeGroups"
                  :key="`duplicate-${index}`"
                >
                  <div class="grid-item-sub" v-for="(dev, idx) in devGroup" :key="`duplicate-${idx}`">
                    <van-image :src="dev.pic" class="developer-image pt-[2px] pb-[2px] px-1" fit="cover" loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="foot_mascot">
          <van-image :src="mascotIcon" class="mascot_icon" width="91" height="50" loading="lazy" />
          <div class="mascot_info">{{$t('mobile.partner.collaboration')}}</div>
          <van-image :src="mascotImage" class="mascot_mascot" width="81" height="122" loading="lazy" />
        </div>

        <FootInfo />
      </div>
    </div>
  </transition>
</template>


<script>
import FootInfo from '@/components/mobile/FootInfo.vue';

export default {
  name: 'CooperationPage',
  components: { FootInfo },
  data() {
    return {
      isContentLoaded: false, // 控制页面加载完成
      bannerImage: require('@/assets/images/partners/partners_banner.jpg'),
      mascotIcon: require('@/assets/images/partners/partners_icon.webp'),
      mascotImage: require('@/assets/images/partners/partners_mascot.webp'),
    };
  },
  computed: {
    developers() {
      return this.$store.getters['common/gameProduct'] || [];
    },
    threeGroups() {
      const totalDevelopers = this.developers.length;
      const groupSize = Math.ceil(totalDevelopers / 3); // 每组的大小
      const groups = [];

      // 将开发者数据平均分成三组
      for (let i = 0; i < 3; i++) {
        const start = i * groupSize;
        const end = start + groupSize;
        groups.push(this.developers.slice(start, end));
      }

      return groups; // 返回三组数据
    },
  },
  watch: {
    developers(newVal) {
      if (newVal.length > 0) {
        this.isContentLoaded = true; // 当开发者数据加载完成后显示页面
        this.setupCarouselSafely();
      }
    },
  },
  mounted() {
    // 如果数据已经在 mounted 时加载好，则立即显示内容
    if (this.developers.length > 0) {
      this.isContentLoaded = true;
      this.setupCarouselSafely();
    }
  },
  methods: {
    setupCarouselSafely() {
      this.$nextTick(() => {
        const track = this.$refs.carouselTrack;
        if (track) {
          this.setupCarousel();
        } else {
          console.error('carouselTrack is undefined');
        }
      });
    },
    setupCarousel() {
      const track = this.$refs.carouselTrack;
      if (!track) return;

      const scrollGroups = track.querySelectorAll('.carousel-item');
      scrollGroups.forEach((group, index) => {
        const totalWidth = group.scrollWidth; // 获取每个组的宽度
        let position = 0;
        const direction = index % 2 === 0 ? -1 : 1; // 第一组和第三组向左，第二组向右

        const scroll = () => {
          position += direction * 0.5; // 控制滚动速度
          if (direction === -1 && position <= -totalWidth) {
            position = 0; // 向左滚动回到起点
          } else if (direction === 1 && position >= totalWidth) {
            position = 0; // 向右滚动回到起点
          }
          group.style.transform = `translateX(${position}px)`; // 使用 transform 滚动
          requestAnimationFrame(scroll); // 使用 requestAnimationFrame 平滑滚动
        };

        requestAnimationFrame(scroll); // 初始调用滚动
      });
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep.cooperation-page {
  background-color: #0D0D0D;
  text-align: center;
  color: $main-font-color;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 20px;
  padding-top: 56px;

  .banner {
    margin-bottom: 20px;
    .van-image__img{
      border-radius: 16px;
    }
  }

  .partner {
    margin-top: 20px;

    .info_default {
      margin: 20px 0;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
    }

    .compy_img {
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-width: 1px 0;
      padding: 10px 0;
      max-height: 267px;
      overflow: hidden;
      .carousel-container {
        width: 100%;
        height: 247px;
        overflow: hidden;
        position: relative;
      }

      .carousel-track {
        will-change: transform;
      }

      .carousel-item {
        flex: 0 0 auto;
        width: 330px;
        display: grid;
        grid-template-rows: repeat(2, 1fr); /* 每组两行 */
        grid-template-columns: repeat(9, 1fr); /* 三列布局 */
        gap: 11px;
        margin-right: 11px;
      }

      .developer-image {
        width: 100px;
        height: 30px;
        background: $card-bg-color;
        border-radius: 12px;
      }
    }
  }

  .foot_mascot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0 35px;

    .mascot_info {
      width: 70%;
      font-weight: 900;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      margin: 20px 0;
    }
  }
}
/* 骨架屏样式 */
.skeleton-banner {
  width: 100%;
  height: 115px;
  margin-bottom: 20px;
  border-radius: 16px;
}

.skeleton-partner {
  .skeleton-title {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 16px;
  }

  .skeleton-info {
    height: 20px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 16px;
  }

  .skeleton-developer-grid {
    width: 100%;
    height: 330px;
    background: $empty-bg-color;
    margin-bottom: 20px;
    border-radius: 16px;
  }
}

.skeleton-mascot {
  width: 100%;
  height: 100px;
  background: $empty-bg-color;
  margin-bottom: 20px;
  border-radius: 16px;
}

.skeleton-footer {
  height: 50px;
  background: $empty-bg-color;
  border-radius: 16px;
}

/* 滚动效果 */
.van-image__error,
.van-image__loading {
  border-radius: 12px;
}
</style>
