<template>
    <div class="content-page">
      <h1>{{$t('publice.disclaimer')}}</h1>
      <SanitizedHtml :htmlContent="content" />
      <p>本网站上的所有信息均仅供参考。本公司对因使用本网站信息所造成的任何损失不承担责任。</p>
    </div>
  </template>
   <script>
   import SanitizedHtml from '@/components/publice/SanitizedHtml.vue';
   export default {
     name:'DDisclaimer',
     components:{
      SanitizedHtml
    },
     computed: {
      title() {
        return this.$route.query.title || 'Default Title';
      },
      content() {
        return this.$route.query.content || 'Default Content';
      }
    }
   }
   </script>
  <style lang="scss" scoped>
  .content-page {
    padding: 20px;
    background-color: $dark-bg-color;
    color: $main-font-color;
    text-align: left;
    min-height: calc(100vh - 198px);
    h1 {
      color: $primary-color;
      margin-bottom: 20px;
    }
  }
  </style>
  