<template>
  <el-card class="game-card" :class="classInfo">
    <div class="image-container">
      <!-- 图片懒加载 -->
      <van-image 
        :src="game.pic ? game.pic : defaultImage" 
        :alt="game.name" 
        class="game-card-image"
        fill="cover"
        lazy-load
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>

      <!-- 半透明遮罩层 -->
      <div class="hover-overlay">
        <div class="play-button">
          <van-icon name="play" size="24px" color="white" />
        </div>
      </div>
    </div>

    <!-- 游戏卡片内容 -->
    <div class="game-card-content">
      <h3 class="game-title overflow-hidden" :title="game.name">{{ game.name }}</h3>
      <p class="game-type" v-show="game.game_type">{{ game.game_type }}</p>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    game: Object,
    classInfo:String
  },
  data() {
    return {
      defaultImage: require('@/assets/images/game/default.jpg')
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-card {
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 16px;
  background-color: #1a1a1a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  position: relative;
  overflow: hidden; /* 确保 ::after 不超出边界 */
  border: none; /* 去掉边框 */
  width: 100%; /* 容器的宽度 */
  &::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  z-index: 0;
  pointer-events: none;
  border-radius: 10px;
}
.el-card__body {
  padding: 0;
  position: relative;
  .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .play-button {
    background-color: #ff4d4f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }

  .hover-overlay:hover {
    opacity: 1;
    z-index: 3;
  }
}
.image-container {
  width: 100%; /* 容器的宽度 */
  height: 0;
  padding-bottom: 115%; /* 16:9 的宽高比，即 9/16 * 100 = 56.25% */
  position: relative;

  
}

.game-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* 减小图片高度 */
  //max-width: 160px;
  //max-height: 213px;
  border-radius: 10px;
  object-fit: cover; /* 保持比例，覆盖整个容器 */
}

.game-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 3px;
  text-align: center;
  z-index: 2;
  text-shadow: 4px 6px 6px rgba(0, 0, 0, 0.8); /* 阴影效果 */
  .game-title {
    font-size: 1.2rem;
    //line-height: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 3px 0;
    color: #fff;
    cursor: pointer;
  }
  .game-type {
    font-size: 12px;
    margin: 0;
    color: #ccc;
    width: 56%;
    padding: 3px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
}


</style>
