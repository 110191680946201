<template>
  <div class="card-list">
    <!-- 游戏卡片网格布局 -->
    <div class="game-grid">
      <div
        v-for="(game,index) in displayedGames"
        :key="game.id"
        class="card"
        @click="playGame(game)"
      >
        <game-card :game="game" :classInfo="getCardClass(index)"/>
      </div>
    </div>

    <!-- 当没有数据时显示 No Data -->
    <div class="no-data" v-show="!displayedGames.length && !load">No Data</div>

    <!-- 加载动画 -->
    <van-loading v-if="load" class="no-data" type="spinner" text="Loading..." spinner="el-icon-loading" />

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        v-if="total > pageSize"
        @current-change="handlePageChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        layout="prev, pager, next"
        class="pagination"
      />
    </div>
    
    <!-- 加载进度条 -->
    <ProgressBar :gameId="selectedGameId" />
  </div>
</template>

<script>
import GameCard from '@/components/desktop/GameCard.vue';
import ProgressBar from '@/components/publice/ProgressBar.vue';

export default {
  name: 'GameContent',
  components: { GameCard, ProgressBar },
  props: {
    games: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedGameId: null,
      load: true,
      displayedGames: [], // 用于显示的游戏数据
      loadTimeout: null // 定时器
    };
  },
  watch: {
    games: {
      immediate: true,
      handler(newVal) {
        this.load = true;
        this.displayedGames = newVal;
        clearTimeout(this.loadTimeout);
        this.loadTimeout = setTimeout(() => {
          this.load = false;
        }, 800); // 模拟加载延迟
      }
    }
  },
  methods: {
     // 根据索引返回对应的类名
     getCardClass(index) {
      const classes =this.topNum?['bg-green', 'bg-purple', 'bg-red', 'bg-orange']:[
      'bg-half-red',
      'bg-half-orange',
      'bg-half-purple',
      //'bg-black', 
      'bg-blue', 
      'bg-light-blue'
    ];
      return classes[index % classes.length];  // 循环分配背景类
    },
    playGame(game) {
      this.selectedGameId = game.id;
    },
    handlePageChange(page) {
      this.$emit('page-change', page);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.game-grid {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
}

.card {
  flex: 0 1 calc(20% - 20px); /* 每行五个卡片 */
  margin: 0 0.8% 20px;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}


@media (max-width: 1200px) {
  .card {
    flex: 0 1 calc(25% - 20px); /* 每行四个卡片 */
  }
}

@media (max-width: 900px) {
  .card {
    flex: 0 1 calc(33.33% - 20px); /* 每行三个卡片 */
  }
}

@media (max-width: 600px) {
  .card {
    flex: 0 1 calc(50% - 20px); /* 每行两个卡片 */
  }
}

@media (max-width: 400px) {
  .card {
    flex: 0 1 calc(100% - 20px); /* 每行一个卡片 */
  }
}

.pagination-container {
  margin-top: auto;
  padding-top: 25px;
  display: flex;
  justify-content: center;
}

.no-data {
  width: 100%;
  text-align: center;
  color: #ccc;
  font-size: 18px;
}
</style>
