<!--游戏详情列表-->
<template>
  <div>
    <GameList />
  </div>
</template>

<script>
import GameList from './GameDefaultListTem.vue';

export default {
  name: 'GameDefaultList',
  components: {
    GameList
  }
};
</script>

<style lang="scss" scoped>
/* 可以添加父组件的样式 */
@import '@/assets/styles/vant-override.scss';
</style>
