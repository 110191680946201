<template>
  <div class="developer-selection m-card">
    <van-row class="header">
      <van-col span="20">
        <h2 class="page-title">{{ $t('mobile.home.gameDevelopers') }}</h2>
      </van-col>
      <van-col span="4" class="view-more py-2 pr-2" @click="goToAllDevelopers">
        {{ $t('publice.more') }}
        <van-icon name="arrow" />
      </van-col>
    </van-row>

    <div class="developer-cards">
      <van-row type="flex" justify="space-around">
        <!-- 显示最多5个开发者 -->
        <van-col 
          v-for="(developer, index) in displayedDevelopers.slice(0, 5)" 
          :key="developer.shortname" 
          :span="4"
          @click="goToAllDevelopers(developer, index)"
        >
          <div class="developer-card">
            <van-image
              fit="contain"
              width="5rem"
              height="2.5rem"
              :src="developer.pic"
              :alt="`${developer.name} Logo`"
              class="developer-logo"
            />
            <p class="game-count">{{ developer.count }} {{ $t('mobile.home.games') }}</p>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeveloperSelection',
  computed: {
    displayedDevelopers() {
      // 优先从 sessionStorage 或 Vuex store 中获取开发者数据
      return  this.$store.getters['common/gameProduct'] || [];
    }
  },
  methods: {
    goToAllDevelopers(developer, index = 0) {
      // 跳转到所有开发者页面并传递当前开发者的信息
      this.$router.push({
        path: '/developers',
        query: {
          activeTab: index,
          shortname: developer.shortname,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.developer-selection {
  padding: 0 20px 20px;
  background-color: transparent;
  margin: 15px 0;
  border: 1px solid $light-border-color;
  border-radius: 8px;
  @include box-shadow($black, 0.8);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .view-more {
      text-align: right;
      color: $primary-color;
      cursor: pointer;
    }
  }

  .developer-cards {
    .van-row {
      width: 100%;
    }

    .developer-card {
      background-color: $card-bg-color;
      padding: 5px 15px;
      border-radius: 8px;
      text-align: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border: 1px solid transparent;
      transition: transform 0.4s;
      min-width: 100px;

      &:hover {
        color: $primary-color;
        cursor: pointer;
        border-color: $primary-color;
        transform: translateY(-5px);
      }

      .developer-logo {
        width: 100%;
        height: auto;
      }

      .game-count {
        color: $primary-color;
        font-size: 14px;
        margin: 5px 0;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
