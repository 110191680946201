<template>
  <div class="verify-page">
    <van-nav-bar left-arrow @click-left="$router.go(-1)" />

    <div class="content">
      <h2 class="title">请输入验证码</h2>

      <p class="email-info">
        验证码已发送至: <span class="email">{{ email }}</span>
      </p>
      <p class="warning">邮箱验证码可能会被判定为垃圾邮件，请注意查收</p>

      <!-- 使用抽离的 VerificationInput 组件 -->
      <VerificationInput
        v-model="code"
        :length="6"
      />

      <div class="resend">
        <span v-if="resendTimer > 0">{{ resendTimer }}s 后重新发送</span>
        <span v-else @click="resendCode" class="resend-link">重新发送验证码</span>
      </div>

      <van-button 
        type="primary" 
        block 
        class="confirm-btn" 
        @click="onSubmit" 
        :disabled="!isCodeComplete"
      >
        确认
      </van-button>
    </div>
  </div>
</template>

<script>
import VerificationInput from '@/components/publice/VerificationInput.vue';

export default {
  name: "MVerifyCode",
  components: {
    VerificationInput
  },
  data() {
    return {
      email: this.$route.query.email || '',
      code: ['', '', '', '', '', ''],  // 验证码6位
      resendTimer: 59,
      isCodeComplete: false
    };
  },
  watch: {
    code(newCode) {
      this.isCodeComplete = newCode.join('').length === 6;
    }
  },
  methods: {
    onSubmit() {
      const fullCode = this.code.join('');
      if (fullCode.length !== 6) {
        this.$toast('请输入完整的验证码');
        return;
      }
      // 提交验证码逻辑
      this.$toast('验证码提交成功');
      localStorage.setItem('isAuthenticated',JSON.stringify({token:'dfrg3435f4'}))
      this.$router.push({ name: 'HomeS' });
    },
    resendCode() {
      this.resendTimer = 59;
      this.$toast('验证码已重新发送');
      // 清空验证码输入框内容
      this.code = ['', '', '', '', '', ''];
      this.startResendTimer();
    },
    startResendTimer() {
      const interval = setInterval(() => {
        if (this.resendTimer > 0) {
          this.resendTimer--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }
  },
  mounted() {
    this.startResendTimer();
  }
};
</script>

<style lang="scss" scoped>
::v-deep.verify-page {
  background-color: $main-bg-color;
  height: 100vh;
  padding: 20px;

  .van-nav-bar {
    background-color: transparent;
    color: $white;
  }

  .content {
    margin-top: 30px;

    .title {
      font-size: 22px;
      font-weight: bold;
      color: $white;
      text-align: center;
    }

    .email-info {
      margin-top: 15px;
      font-size: 14px;
      text-align: center;
      color: $white;

      .email {
        color: $success-color;
      }
    }

    .warning {
      margin-top: 10px;
      font-size: 12px;
      text-align: center;
      color: $warning-color;
    }

    .resend {
      margin-top: 15px;
      text-align: center;
      font-size: 12px;
      color: $white;

      .resend-link {
        color: $primary-color;
        cursor: pointer;
      }
    }

    .confirm-btn {
      margin-top: 30px;
      background-color: $button-bg-color;
      border-radius: $button-border-radius;
      height: 48px;
    }
  }
}
</style>
