<template>
    <div class="comment">
      <div class="comment-author">
        <slot name="author"></slot>
      </div>
      <div class="comment-content">
        <slot name="content"></slot>
      </div>
      <div class="comment-actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PComment',
  };
  </script>
  
  <style lang="scss" scoped>
  .comment {
    padding: 10px;
    background-color: $comment-bg-color;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .comment-author {
    font-weight: bold;
    margin-bottom: 5px;
    color: $white;
  }
  
  .comment-content {
    margin-bottom: 10px;
    color: $white;
  }
  
  .comment-actions {
    display: flex;
    align-items: center;
  }
  </style>
  