<template>
  <div class="login-page m-card">
    <van-nav-bar left-arrow @click-left="$router.go(-1)" />
    <div class="title">欢迎登录</div>
    
    <van-tabs v-model="activeTab" class="custom-tabs">
      <!-- 邮箱/账号登录 -->
      <van-tab title="邮箱/账号">
        <van-field v-model="email" placeholder="请输入邮箱或账号" class="custom-field" />
        <van-field v-model="password" placeholder="请输入密码" type="password" class="custom-field" />
        <van-button type="primary" block @click="onSubmit" class="custom-button">
          登录
        </van-button>
      </van-tab>

      <!-- 手机号登录 -->
      <van-tab title="手机号">
        <van-field v-model="phone" placeholder="请输入手机号" class="custom-field">
          <template #prepend>
            <van-dropdown-menu>
              <van-dropdown-item v-model="phoneCode" :options="phoneCodes" />
            </van-dropdown-menu>
          </template>
        </van-field>
        <van-button type="primary" block @click="onSubmit" class="custom-button">下一步</van-button>
      </van-tab>

      <!-- Telegram 登录 -->
      <van-tab title="Telegram">
        <van-field v-model="telegram" placeholder="请输入Telegram用户名" class="custom-field">
          <template #prepend>
            <span>@</span>
          </template>
        </van-field>
        <p class="telegram-info">更换了Telegram号?</p>
        <van-button type="primary" block @click="onSubmit" class="custom-button">下一步</van-button>
      </van-tab>
      
    </van-tabs>

    <p class="login-link">
      <span v-if="activeTab === 0" @click="goToPasswordLogin">邮箱验证码登录<span class="line"> | </span></span>
      <span v-if="activeTab === 2" @click="goToPasswordLogin">未收到验证码?<span class="line"> | </span></span>
      <span @click="goToRegister">新用户注册</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "MLogin",
  data() {
    return {
      activeTab: 0,
      email: '',
      phone: '',
      telegram: '',
      password: '',
      phoneCode: '+65', // 默认手机号区号
      phoneCodes: [
        { text: '+65', value: '+65' },
        { text: '+86', value: '+86' },
        { text: '+1', value: '+1' },
        // 其他区号可根据需求添加
      ]
    };
  },
  methods: {
    onLoginSuccess() {
    // 获取 redirect 参数，如果没有则重定向到主页
    const redirectPath = this.$route.query.redirect || '/mobile/home';
    this.$router.push(redirectPath);  // 登录后重定向到目标页面
  },
  onSubmit() {
    if (this.activeTab === 0 && this.email && this.password) {
      console.log('Email or Account:', this.email, 'Password:', this.password);
      if(this.email === 'admin@gmail.com' && this.password === '123456') {
        this.$toast.success('登录成功！');
        localStorage.setItem('isAuthenticated', JSON.stringify({ token: 'dfrg3435f4' }));
        this.onLoginSuccess(); // 登录成功后调用重定向
      } else {
        this.$toast.fail('用户名或密码错误！');
      }
    } else {
      this.$toast('请输入有效的登录信息');
    }
  },
    goToPasswordLogin() {
      this.$router.push({ name: 'MPasswordLogin' });
    },
    goToRegister() {
      this.$router.push({ name: 'MRegistration' });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  background-color: $main-bg-color;
  height: 100%;
  box-sizing: border-box;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
    color: $main-font-color;
  }

  .van-nav-bar {
    width: 100%;
    background-color: transparent;
    color: $main-font-color;
  }

  .custom-tabs {
    width: 100%;
    margin: 20px 0;
    background-color: $nav-bg-color;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 25px;

    .van-tabs__wrap {
      border-bottom: 1px solid $border-color;
      margin-bottom: 20px;

      .van-tabs__nav {
        background-color: transparent;
        .van-tabs__line {
          background-color: transparent;
        }
      }
    }

    .van-tab {
      border-bottom: 1px solid $border-color;
      padding: 10px 15px;
      background-color: $nav-bg-color;
      border-radius: 8px;
      color: $secondary-font-color;

      &.van-tab--active {
        background-color: $primary-color;
        color: $black;
        border-bottom: 2px solid $primary-color;
      }
    }

    .van-tabs__content {
      padding: 5px 15px;

      .van-cell-group {
        background-color: transparent;
      }

      .van-cell::after {
        border-width: 0;
      }
    }
  }

  .custom-field {
    width: 100%;
    background-color: $input-bg-color;
    color: $main-font-color;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    margin-bottom: 15px;

    &:hover {
      border: 1px solid $button-hover-color;
      color: $button-hover-color;
    }

    .van-field__control {
      color: $main-font-color;
    }
    
    &::placeholder {
      color: $placeholder-color;
    }
  }

  .custom-button {
    width: 100%;
    margin-top: 30px;
    background: linear-gradient(to right, $button-bg-gradient-start, $button-bg-gradient-end);
    border-radius: $button-border-radius;
    box-shadow: $shadow-deep;
    border: none;
    font-weight: bold;
    color: $white;
    height: 48px;
  }

  .login-link {
    margin-top: 20px;
    color: $secondary-font-color;

    span {
      color: $primary-color;
      cursor: pointer;
      font-weight: bold;
    }

    span.line {
      color: $secondary-font-color;
    }
  }

  .telegram-info {
    margin-top: 10px;
    color: $primary-color;
    text-align: left;
    font-size: 14px;
  }
}
</style>
