<template>
  <div class="game-detail m-card">
    <van-nav-bar
      title="游戏详情"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="game-header">
      <img v-if="game.coverImage" :src="game.coverImage" alt="Game Cover" class="game-cover" />
      <video v-if="game.coverVideo" :src="game.coverVideo" controls class="game-video"></video>
    </div>
    <div class="game-content">
      <h1 class="game-title">{{ game.title }}</h1>
      <p class="game-description">{{ game.description }}</p>
      <h2 class="section-title">功能</h2>
      <ul class="game-features">
        <li v-for="(feature, index) in game.features" :key="index" class="feature-item">{{ feature }}</li>
      </ul>
      <h2 class="section-title">截图</h2>
      <div class="screenshots">
        <img v-for="(screenshot, index) in game.screenshots" :src="screenshot" :key="index" class="screenshot" />
      </div>
      <h2 class="section-title">评论</h2>
      <div class="comments">
        <div v-for="(comment, index) in game.comments" :key="index" class="comment">
          <p><strong>{{ comment.author }}:</strong> {{ comment.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameDetail',
  data() {
    return {
      game: {
        title: '',
        coverImage: '',
        coverVideo: '',
        description: '',
        features: [],
        screenshots: [],
        comments: []
      },
      info: JSON.parse(this.$route.query.info),
    };
  },
  created() {
    this.fetchGameData(this.info);
  },
  methods: {
    fetchGameData(info) {
      // 模拟从服务器加载数据
      setTimeout(() => {
        this.game = {
          title: info.name,
          coverImage: require('@/assets/images/game/default.jpg'),
          coverVideo: '',
          description: '这是一个示例游戏的描述。',
          features: ['功能1', '功能2', '功能3'],
          screenshots: [
            require('@/assets/images/game/default.jpg'),
            require('@/assets/images/game/default.jpg'),
            require('@/assets/images/game/default.jpg')
          ],
          comments: [
            { author: '用户1', text: '这是一条评论。' },
            { author: '用户2', text: '这是另一条评论。' }
          ]
        };
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.game-detail {
  padding: 0 16px 40px;
  background-color: $dark-gray;
  color: $white;
}

.game-header {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: $dark-gray-bar;
  @include box-shadow($black,0.2,0,2px,4px);
}

.game-cover, .game-video {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  @include box-shadow($black,0.3);
}

.game-content {
  padding: 16px;
  border-radius: 8px;
  background-color: $dark-gray-bar;
  @include box-shadow($black,0.1,0,2px,4px);
}

.game-title {
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: bold;
}

.game-description {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: $light-gray;
}

.section-title {
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: bold;
  color: $white;
  border-left: 4px solid $primary-color;
  padding-left: 8px;
}

.game-features {
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
}

.feature-item {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 16px;
  background-color: $input-bg-color;
}

.screenshots {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.screenshot {
  width: calc(33.333% - 8px);
  border-radius: 4px;
  @include box-shadow($black,0.2,0,1px,3px);
  background-color: $black;
}

.comments {
  margin-top: 16px;
}

.comment {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: $input-bg-color;
  @include box-shadow($black,0.2,0,1px,3px);
}

.comment p {
  margin: 0;
  color: $white;
}
</style>
