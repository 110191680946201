<template>
  <div class="services-page mt-0">
    <!-- 骨架屏在页面未加载完成时显示 -->
   <SkeletonLoader :isContentLoaded="isContentLoaded"/>
    <!-- 页面真实内容在加载完成后显示 -->
    <div v-if="isContentLoaded">
      <main>
        <!-- Banner Section -->
        <ServiceBanner/>

        <!-- Service Section -->
        <ServiceCard/>

        <!-- About Info Section -->
        <AboutInfo/>
      </main>
      <FootInfo />
    </div>
  </div>
</template>
 
<script>
import FootInfo from '@/components/mobile/FootInfo.vue';
import ServiceBanner from './ServiceBanner.vue';
import SkeletonLoader from './SkeletonLoader.vue';
import ServiceCard from './ServiceCard.vue';
import AboutInfo from './AboutInfo.vue';
export default {
  name: 'ServicePage',
  components: { FootInfo,SkeletonLoader,ServiceBanner,ServiceCard,AboutInfo },
  data() {
    return {
      isContentLoaded: false, // 控制是否加载完成
    };
  },
 mounted() {
  // 页面加载完成后，展示内容
  this.$nextTick(() => {
    this.isContentLoaded = true;
  });
},
};

</script>

<style scoped lang="scss">
main {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>