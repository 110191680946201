<template>
    <div class="notification-detail m-card">
      <van-nav-bar
        title="消息详情"
        left-arrow
        @click-left="$router.go(-1)"
      />
      <div class="content">
        <!-- 标题 -->
        <h2 class="notification-title">{{ notification.title }}</h2>
        <!-- 时间 -->
        <p class="notification-date">{{ notification.date }}</p>
        <!-- 内容 -->
        <div class="notification-content" v-html="notification.content"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotificationDetail',
    data() {
      return {
        notification: JSON.parse(this.$route.query.notification) || {
          title: '示例消息标题',
          date: '2023-01-01',
          content: '<p>这是一条示例消息内容。这里是消息的详细描述，可以包含多行文本。</p>'
        }
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/styles/vant-override.scss';
  
  .notification-detail {
    padding: 16px;
    height: calc(100% - 32px);
  
    .content {
      background-color: $dark-gray-bar;
      border-radius: 8px;
      padding: 20px;
      margin-top: 16px;
      min-height: 200px;
      @include box-shadow($black, 0.5, 0, 2px, 4px);
  
      .notification-title {
        color: $white;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 12px;
        text-align: center; /* 居中标题 */
      }
  
      .notification-date {
        color: $light-gray;
        font-size: 14px;
        margin-bottom: 20px;
        text-align: center; /* 居中时间 */
      }
  
      .notification-content {
        color: $white;
        font-size: 16px;
        line-height: 1.6;
        text-align: left;
        text-indent: 2em; /* 首行缩进 */
      }
  
      /* 保证通知内容中的 HTML 被正确渲染 */
      .notification-content p {
        margin-bottom: 1em;
      }
    }
  }
  </style>
  