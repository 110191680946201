import request from '@/utils/request'
import axiosInstance from './axiosInstance';
export function findAllMeta() {
  return request({
    url: '/meta',
    method: 'get'
  })
}

//获取平台配置
export const getCommonConfig = () => {
  return axiosInstance.get('/index/game/config');
};
//获取平台帮助
//
export const getGameHelp = () => {
  return axiosInstance.get('/index/game/help');
};
