<template>
  <div class="m-card">
    <van-nav-bar
      title="搜索"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <van-search v-model="search" show-action @search="onSearch" />
    <van-list v-model="loading" :finished="finished">
      <van-cell v-for="(result, index) in results" :key="index" :title="result.title" />
    </van-list>
  </div>
</template>

<script>
export default {
  name: 'SearchS',
  data() {
    return {
      search: '',
      results: [],
      loading: false,
      finished: true
    };
  },
  methods: {
    onSearch() {
      // 模拟搜索
      this.results = [
        { title: '结果1' },
        { title: '结果2' },
        { title: '结果3' }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vant-override.scss';
::v-deep .van-search{
  .van-search__input {
  color: $white !important; /* 输入框中的文字颜色为白色 */
}

.van-field__control {
  color: $white !important; /* 确保输入框的文字颜色为白色 */
}

/* 处理 placeholder 的颜色 */
.van-field__control::placeholder {
  color: rgba($white, 0.6) !important; /* 调整 placeholder 的颜色，白色透明度60% */
}
}

</style>
