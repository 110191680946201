<template>
    <div class="login-page m-card">
      <van-nav-bar left-arrow @click-left="$router.go(-1)" />
      <div class="title">密码登录</div>
  
      <div class="form-container">
        <!-- 邮箱/账号显示 -->
        <van-field v-model="email" label="邮箱/账号" :disabled="true" class="custom-field" />
  
        <!-- 图形验证码 -->
        <div class="captcha-container">
          <van-field v-model="captchaCode" placeholder="请输入图形验证码" class="custom-field" />
          <van-image :src="captchaImage" width="100" height="40" @click="refreshCaptcha" class="captcha-image" />
        </div>
  
        <!-- 验证码输入框和发送按钮 -->
        <div class="verification-container">
          <van-field v-model="smsCode" placeholder="请输入验证码" class="custom-field" />
          <van-button type="primary" class="send-code-btn" @click="sendSmsCode" :disabled="isSending">
            {{ isSending ? '已发送' : '发送验证码' }}
          </van-button>
        </div>
  
        <!-- 下一步按钮 -->
        <van-button type="primary" block class="next-step-btn" @click="nextStep">
          下一步
        </van-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PasswordLogin',
    data() {
      return {
        email: 'wander@example.com',  // 模拟显示的邮箱
        captchaCode: '',
        captchaImage: 'https://fakeimg.pl/100x40/',  // 图形验证码的图片
        smsCode: '',
        isSending: false,
      };
    },
    methods: {
      // 刷新图形验证码
      refreshCaptcha() {
        this.captchaImage = 'https://fakeimg.pl/100x40/?' + Math.random();
      },
      // 发送短信验证码
      sendSmsCode() {
        this.isSending = true;
        // 模拟验证码发送，3秒后允许再次发送
        setTimeout(() => {
          this.isSending = false;
        }, 3000);
      },
      // 下一步逻辑
      nextStep() {
        if (!this.smsCode) {
          this.$toast('请输入验证码');
          return;
        }
        this.$toast('验证成功，跳转中...');
        // 跳转到下一个页面
        this.$router.push({ name: 'NextPage' });
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  ::v-deep.login-page {
    background-color: $main-bg-color;
    height: 100%;
    padding: 0 20px;
    .van-cell::after {
    border-width: 0;
  }
    .title {
      font-size: 24px;
      font-weight: 600;
      margin-top: 20px;
      color: $main-font-color;
      text-align: center;
    }
  
    .form-container {
      margin-top: 30px;
  
      .custom-field {
        margin-bottom: 15px;
        background-color: $input-bg-color;
        border-radius: 5px;
  
        .van-field__control {
          color: $main-font-color;
        }
  
        .van-field__label {
          color: $main-font-color;
        }
      }
  
      .captcha-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .captcha-image {
          margin-left: 10px;
          cursor: pointer;
        }
      }
  
      .verification-container {
        display: flex;
        justify-content: space-between;
        align-items: self-start;
        margin-bottom: 15px;
        .send-code-btn {
          font-size: 12px!important;
          font-weight: 600!important;
          width: 120px;
          margin-left: 10px;
          background-color: $button-bg-color;
          border-radius: 5px;
        }
      }
  
      .next-step-btn {
        margin-top: 30px;
        background-color: $button-bg-color;
        border-radius: $button-border-radius;
        box-shadow: $shadow-deep;
      }
    }
  }
  </style>
  