<template>
  <div class="game-list pt-10 px-4 pb-14">
    <van-nav-bar
      class="title w-full"
      :title="name"
       left-arrow
      @click-left="$router.go(-1)"
      border
    >
      <template #right>
        <van-search
          v-model="searchValue"
          :placeholder="$t('publice.keyWords')"
        />
      </template>
    </van-nav-bar>

    <!-- 使用封装后的 LazyGameList 组件 -->
    <LazyGameList
      :gameType="name"
      :searchValue="searchValue"
      @game-list-updated="handleGameListUpdated"
      @view-game-detail="viewGameDetail"
      @toggle-recommendation="toggleRecommendation"
    />

    <!-- 加载进度条 -->
    <ProgressBar :gameId="selectedGameId" />
  </div>
</template>

<script>
import LazyGameList from '@/components/publice/LazyGameList.vue'; // 组件路径确保正确
import ProgressBar from '@/components/publice/ProgressBar.vue';

export default {
  name: 'GameList',
  components: {
    ProgressBar,
    LazyGameList
  },
  data() {
    return {
      name: this.$route.query.name, // 游戏列表的名称
      selectedGameId: null, // 当前选择的游戏 ID
      searchValue: '', // 搜索框的值
    };
  },
  methods: {
    // 处理从 LazyGameList 中传回的游戏列表
    handleGameListUpdated() {
      //console.log('游戏列表更新:', games);
    },
    // 查看游戏详情
    viewGameDetail(game) {
      this.selectedGameId = game.id;
    },
    // 切换游戏推荐状态
    toggleRecommendation(game) {
      game.status = !game.status;
    }
  }
};
</script>

<style lang="scss" scoped>
.game-list {
  //padding: 0px 10px 40px 0;
  color: $white;
}

::v-deep.van-nav-bar {
  width: 100%;
  background-color: transparent!important;
  margin: 10px 0 15px;

  .van-icon,
  .van-nav-bar__title {
    color: $white !important;
  }
  .van-nav-bar__title {
    max-width: 160px;
    margin: 0 58px;
    color: #323233;
    font-weight: 500;
    font-size: 16px;
    font-weight: 600;
  }
  .van-nav-bar__right {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 0 5px;
    .van-search {
      width: 55%;
      max-width: 270px;
      padding: 5px 12px;
      border-radius: 25px;
      margin: 10px;
      .van-icon-search{
        font-size: 22px;
      }
    }
  }
}

.title {
  background-color: transparent !important;
}

.game-card {
  width: 92%;
  display: flex;
  margin-bottom: 20px;
  padding: 15px;
  background-color: $dark-gray;
  border-radius: 10px;
  @include box-shadow($black, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.game-image {
  width: 100px;
  height: 120px;
  border-radius: 8px;
  margin-right: 15px;
  object-fit: contain;
}

.game-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game-title {
  color: $gold;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.game-details {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  color: $star-filled-color;
  font-size: 14px;
}

.game-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.play-button {
  height: 40px;
  width: 52%;
  font-size: 16px !important;
  padding: 5px 12px; /* 增加 padding 调整文本显示 */
  color: $white;
  white-space: nowrap; /* 防止文字换行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 超长文字显示省略号 */
  text-transform: uppercase;
  font-weight: bold;
}

.game-recommendation {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: $star-filled-color;
  font-size: 14px;
  margin-left: 10px;
  .van-icon {
    padding-right: 5px;
  }
}
</style>
