<template>
  <div id="app">
    <!-- <header>
      <van-button type="primary" @click="changeLanguage('en-US')">English</van-button>
      <van-button type="primary" @click="changeLanguage('zh-CN')">中文</van-button>
    </header> -->
    <component :is="layoutComponent">
      <router-view />
    </component>
  </div>
</template>

<script>
import { Locale } from 'vant';
import enUS from 'vant/lib/locale/lang/en-US';
import zhCN from 'vant/lib/locale/lang/zh-CN';
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import resizeMixin from './mixins/resizeMixin';

export default {
  name: 'App',
  mixins: [resizeMixin],
  watch: {
    isMobile() {
      this.updateRoutes();
    }
  },
  created() {
    // 在应用初始化时获取公共配置
    this.$store.dispatch('common/fetchCommonConfig');
    this.$store.dispatch('common/fetchGameHelp');
    this.$store.dispatch('common/fetchGameType');
    this.$store.dispatch('common/fetchGameBanner');
    this.$store.dispatch('common/fetchGameProduct');
    
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      const localeMessages = lang === 'en-US' ? enUS : zhCN;
      const elementMessages = lang === 'en-US' ? elementEnLocale : elementZhLocale;
      Locale.use(lang, localeMessages);
      this.$i18n.setLocaleMessage(lang, {
        ...this.$i18n.getLocaleMessage(lang),
        ...elementMessages
      });
    },
    updateRoutes() {
      this.$router.go(0); // Reload the page to apply the correct route
    }
  },
  computed: {
    layoutComponent() {
      return this.isMobile ? 'MobileLayout' : 'DesktopLayout';
    }
  },
  components: {
    MobileLayout: () => import('./layouts/mobile/MobileLayout.vue'),
    DesktopLayout: () => import('./layouts/desktop/DesktopLayout.vue')
  }
};
</script>

<style lang="scss" scoped>
/* 使用引入的字体 */
$chinese-font: 'Noto Sans SC', sans-serif;
$english-font: 'Roboto', sans-serif;

body {
  font-family: Poppins, Poppins, sans-serif;
  color: $main-font-color;
  background-color: $main-bg-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Poppins, Poppins, sans-serif; // 英文字体优先
  font-weight: 700;
}

p {
  font-family: Poppins, Poppins, sans-serif; // 中文字体优先
  font-weight: 400;
}
#app {
  text-align: center;
  color: $main-font-color;
  background-color: $main-bg-color;
}
header {
  margin-bottom: 20px;
}

</style>
