<template>
    <div class="main-content-area">
      <div class="content-row"  style="margin-top: 15px;">
        <!-- 游戏内容展示组件 -->
        <div class="content-area">
          <game-content
            :games="games"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @page-change="handlePageChange"
          />
        </div>
  
        <!-- 右侧筛选栏 -->
        <div class="search-bar">
          <search-sidebar
            :search-query="searchQuery"
            :selected-popularity="selectedPopularity"
            :selected-type="selectedType"
            :selected-language="selectedLanguage"
            :selected-product="selectedProduct"
            @update:searchQuery="updateSearchQuery"
            @update:selectedPopularity="updateSelectedPopularity"
            @update:selectedType="updateSelectedType"
            @update:selectedLanguage="updateSelectedLanguage"
            @update:selectedProduct="updateSelectedProduct"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import GameContent from '@/views/desktop/home/GameContent.vue';
  import SearchSidebar from '@/views/desktop/home/SearchSidebar.vue';
  import { gameList } from '@/api/services/game'; // 引入 gameList 接口
  
  export default {
    name: 'MainContentArea',
    components: {
      GameContent,
      SearchSidebar,
    },
    props:{
        type:{
            type:String,
            default:''
        }
    },
    data() {
      return {
        games: [],          // 游戏数据
        currentPage: 1,     // 当前页码
        pageSize: 15,       // 每页显示的游戏数
        total: 0,           // 游戏总数
        searchQuery: '',    // 搜索关键词
        selectedPopularity: '', // 热度筛选条件
        selectedType: this.type,       // 类型筛选条件
        selectedLanguage: '',   // 语言筛选条件
        selectedProduct: '',    // 产品筛选条件
      };
    },
    watch: {
      // 监听筛选条件变化，重新请求游戏列表
      searchQuery: 'getGamelistHandler',
      selectedPopularity: 'getGamelistHandler',
      selectedType: 'getGamelistHandler',
      selectedLanguage: 'getGamelistHandler',
      selectedProduct: 'getGamelistHandler',
      currentPage: 'getGamelistHandler',
    },
    mounted() {
      this.getGamelistHandler(); // 初始化加载游戏列表
    },
    methods: {
      // 获取游戏列表
      async getGamelistHandler() {
        try {
          const params = {
            keywords: this.searchQuery,
            recommend: this.selectedPopularity,
            game_type: this.selectedType,
            language: this.selectedLanguage,
            product: this.selectedProduct,
            pageNumber: this.currentPage,
            pageSize: this.pageSize,
          };
          const res = await gameList(params); // 发起 API 请求
          this.games = res.data.rows;         // 更新游戏列表
          this.total = res.data.total;        // 更新总条目数
        } catch (error) {
          console.error('Error fetching game list:', error);
        }
      },
      // 页码改变时的处理
      handlePageChange(page) {
        this.currentPage = page; // 更新当前页码，并自动触发 getGamelistHandler
      },
      // 处理子组件传来的事件
      updateSearchQuery(newQuery) {
        this.searchQuery = newQuery;
      },
      updateSelectedPopularity(newPopularity) {
        this.selectedPopularity = newPopularity;
      },
      updateSelectedType(newType) {
        this.selectedType = newType;
      },
      updateSelectedLanguage(newLanguage) {
        this.selectedLanguage = newLanguage;
      },
      updateSelectedProduct(newProduct) {
        this.selectedProduct = newProduct;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .main-content-area {
    padding-top: 25px;
  }
  
  .content-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .content-area{
    margin-right: 10px;
    width:100%
  }
  .search-bar {
    border-radius: 8px;
    max-width: 300px;
  }
  </style>
  